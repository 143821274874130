import * as jwtDecode from "jwt-decode";

export function tokenStorage() {
  const getAccessToken = () => localStorage.getItem("accessToken");
  const getRefreshToken = () => localStorage.getItem("refreshToken");

  const getGoogleAccessToken = () => localStorage.getItem("googleAccessToken");
  const getGoogleRefreshAccessToken = () =>
    localStorage.getItem("googleRefreshAccessToken");

  const setAccessToken = (token: string) =>
    localStorage.setItem("accessToken", token);
  const setRefreshToken = (token: string) =>
    localStorage.setItem("refreshToken", token);

  const setGoogleAccessToken = (token: string) =>
    localStorage.setItem("googleAccessToken", token);
  const setGoogleRefreshAccessToken = (token: string) =>
    localStorage.setItem("googleRefreshAccessToken", token);

  return {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
    getGoogleAccessToken,
    setGoogleAccessToken,
    getGoogleRefreshAccessToken,
    setGoogleRefreshAccessToken,
  };
}

export const getCurrentUserId = (token: any) => {
  if (token) {
    const decodedToken: any = jwtDecode.jwtDecode(token);
    const userId = decodedToken.id;

    return userId;
  }
};
