import { newPasswordProps } from "../../types";
import { SET_NEW_PASSWORD_FAILURE, SET_NEW_PASSWORD_REQUEST, SET_NEW_PASSWORD_SUCCESS } from "../actions/setNewPassword";

interface NewPasswordState {
  loading: boolean;
  password: any
  error: string | null;
}

const initialState: NewPasswordState = {
  loading: false,
  password: null,
  error: null,
};

interface NewPasswordRequestAction {
  type: typeof SET_NEW_PASSWORD_REQUEST;
}

interface NewPasswordSuccessAction {
  type: typeof SET_NEW_PASSWORD_SUCCESS;
  payload: newPasswordProps
}

interface NewPasswordFailureAction {
  type: typeof SET_NEW_PASSWORD_FAILURE;
  payload: string;
}

export type NewPasswordActionTypes =
  | NewPasswordRequestAction
  | NewPasswordSuccessAction
  | NewPasswordFailureAction;

const setNewPasswordReducer = (state = initialState, action: any): NewPasswordState => {
  switch (action.type) {
    case SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        password: action.payload,
        error: null,
      };
    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default setNewPasswordReducer;
