import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@mui/material";
// import axios from "axios";
// import { tokenStorage } from "../../helpers/storageFunctions";
import { googleLogin } from "../../api/googleApiService";

type GoogleLoginButtonProps = {
  setGoogleTokenAuth: (value: string) => void;
};

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  setGoogleTokenAuth,
}) => {
  const handleLoginSuccess = async (codeResponse: any) => {
    const accessToken = await googleLogin(codeResponse);
    setGoogleTokenAuth(accessToken);
  };

  const signIn = useGoogleLogin({
    onSuccess: (codeResponse) => handleLoginSuccess(codeResponse),
    flow: "auth-code",
    onError: () => console.error("Login failed"),
  });

  return (
    <Button
      onClick={() => signIn()}
      sx={{
        width: "100%",
        marginTop: "5px",
        background: "#7E57C2",
        color: "#FFF",
      }}
    >
      {"Login into Google"}
    </Button>
  );
};

export default GoogleLoginButton;
