import { Employee, Property } from "../../../types";
import {
  CREATE_PROPERTY_COMMENT_FAILURE,
  CREATE_PROPERTY_COMMENT_REQUEST,
  CREATE_PROPERTY_COMMENT_SUCCESS,
  CREATE_PROPERTY_FAILURE,
  CREATE_PROPERTY_REQUEST,
  CREATE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_DOCUMENTS_FAILURE,
  DELETE_PROPERTY_DOCUMENTS_REQUEST,
  DELETE_PROPERTY_DOCUMENTS_SUCCESS,
  DELETE_PROPERTY_FAILURE,
  DELETE_PROPERTY_IMAGE_FAILURE,
  DELETE_PROPERTY_IMAGE_REQUEST,
  DELETE_PROPERTY_IMAGE_SUCCESS,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  GET_ALL_PROPERTIES_FAILURE,
  GET_ALL_PROPERTIES_REQUEST,
  GET_ALL_PROPERTIES_SUCCESS,
  GET_PROPERTY_BY_ID_FAILURE,
  GET_PROPERTY_BY_ID_REQUEST,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_COMMENTS_FAILURE,
  GET_PROPERTY_COMMENTS_REQUEST,
  GET_PROPERTY_COMMENTS_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPLOAD_PROPERTY_DOCUMENTS_FAILURE,
  UPLOAD_PROPERTY_DOCUMENTS_REQUEST,
  UPLOAD_PROPERTY_DOCUMENTS_SUCCESS,
  UPLOAD_PROPERTY_IMAGE_FAILURE,
  UPLOAD_PROPERTY_IMAGE_REQUEST,
  UPLOAD_PROPERTY_IMAGE_SUCCESS,
} from "../../actions/property/property";

interface Document {
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
  uploadedAt: string;
  propertyId: string;
}

interface Image {
  id: string;
  url: string;
  uploadedAt: string;
  propertyId: string;
}

interface Comment {
  id: number;
  message: string;
  employee: Employee;
  createdAt: Date;
}

interface PropertyState {
  loading: boolean;
  properties: Property[];
  totalPages: number;
  error: string | null;
  documents?: Document[];
  images?: Image[];
  comments?: Comment[];
}

const initialState: PropertyState = {
  loading: false,
  properties: [],
  totalPages: 0,
  error: null,
  documents: [],
  comments: [],
};

interface GetAllPropertiesRequestAction {
  type: typeof GET_ALL_PROPERTIES_REQUEST;
}

interface GetAllPropertiesSuccessAction {
  type: typeof GET_ALL_PROPERTIES_SUCCESS;
  payload: Property[];
}

interface GetAllPropertiesFailureAction {
  type: typeof GET_ALL_PROPERTIES_FAILURE;
  payload: string;
}

interface GetPropertyByIdRequestAction {
  type: typeof GET_PROPERTY_BY_ID_REQUEST;
}

interface GetPropertyByIdSuccessAction {
  type: typeof GET_PROPERTY_BY_ID_SUCCESS;
  payload: Property[];
}

interface GetPropertyByIdFailureAction {
  type: typeof GET_PROPERTY_BY_ID_FAILURE;
  payload: string;
}

interface CreatePropertyRequestAction {
  type: typeof CREATE_PROPERTY_REQUEST;
}

interface CreatePropertySuccessAction {
  type: typeof CREATE_PROPERTY_SUCCESS;
  payload: Property[];
}

interface CreatePropertyFailureAction {
  type: typeof CREATE_PROPERTY_FAILURE;
  payload: string;
}

interface UpdatePropertyRequestAction {
  type: typeof UPDATE_PROPERTY_REQUEST;
}

interface UpdatePropertySuccessAction {
  type: typeof UPDATE_PROPERTY_SUCCESS;
  payload: Property[];
}

interface UpdatePropertyFailureAction {
  type: typeof UPDATE_PROPERTY_FAILURE;
  payload: string;
}

interface DeletePropertyRequestAction {
  type: typeof DELETE_PROPERTY_REQUEST;
}

interface DeletePropertySuccessAction {
  type: typeof DELETE_PROPERTY_SUCCESS;
  payload: Property[];
}

interface DeletePropertyFailureAction {
  type: typeof DELETE_PROPERTY_FAILURE;
  payload: string;
}

export type PropertyActionType =
  | GetAllPropertiesRequestAction
  | GetAllPropertiesSuccessAction
  | GetAllPropertiesFailureAction
  | GetPropertyByIdRequestAction
  | GetPropertyByIdSuccessAction
  | GetPropertyByIdFailureAction
  | CreatePropertyRequestAction
  | CreatePropertySuccessAction
  | CreatePropertyFailureAction
  | UpdatePropertyRequestAction
  | UpdatePropertySuccessAction
  | UpdatePropertyFailureAction
  | DeletePropertyRequestAction
  | DeletePropertySuccessAction
  | DeletePropertyFailureAction;

const propertyReducer = (state = initialState, action: any): PropertyState => {
  switch (action.type) {
    case GET_ALL_PROPERTIES_REQUEST:
    case GET_PROPERTY_BY_ID_REQUEST:
    case CREATE_PROPERTY_REQUEST:
    case UPDATE_PROPERTY_REQUEST:
    case DELETE_PROPERTY_REQUEST:
    case UPLOAD_PROPERTY_DOCUMENTS_REQUEST:
    case DELETE_PROPERTY_DOCUMENTS_REQUEST:
    case UPLOAD_PROPERTY_IMAGE_REQUEST:
    case DELETE_PROPERTY_IMAGE_REQUEST:
    case GET_PROPERTY_COMMENTS_REQUEST:
    case CREATE_PROPERTY_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROPERTY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: state.documents?.filter(
          (document) => document.id !== action.payload.id
        ),
        error: null,
      };
    case DELETE_PROPERTY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        images: state.images?.filter((image) => image.id !== action.payload.id),
        error: null,
      };
    case UPLOAD_PROPERTY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: [...(state.documents || []), action.payload],
        error: null,
      };
    case UPLOAD_PROPERTY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        images: [...(state.images || []), action.payload],
        error: null,
      };
    case GET_PROPERTY_BY_ID_SUCCESS:
    case UPDATE_PROPERTY_SUCCESS:
    case DELETE_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        properties: action.payload,
        error: null,
      };
    case GET_ALL_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        properties: action.payload.properties,
        totalPages: action.payload.totalPages,
        error: null,
      };
    case CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        properties: [...(state.properties || []), action.payload],
        error: null,
      };
    case GET_PROPERTY_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: action.payload,
        error: null,
      };
    case CREATE_PROPERTY_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: [...(state.comments || []), action.payload],
        error: null,
      };
    case GET_ALL_PROPERTIES_FAILURE:
    case GET_PROPERTY_BY_ID_FAILURE:
    case CREATE_PROPERTY_FAILURE:
    case UPDATE_PROPERTY_FAILURE:
    case DELETE_PROPERTY_FAILURE:
    case UPLOAD_PROPERTY_DOCUMENTS_FAILURE:
    case DELETE_PROPERTY_DOCUMENTS_FAILURE:
    case UPLOAD_PROPERTY_IMAGE_FAILURE:
    case DELETE_PROPERTY_IMAGE_FAILURE:
    case GET_PROPERTY_COMMENTS_FAILURE:
    case CREATE_PROPERTY_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default propertyReducer;
