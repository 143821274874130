import { configureStore } from "@reduxjs/toolkit";
import signinReducer from "./reducers/signin";
import updateAdminContactReducer from "./reducers/updateAdminContact";
import updateBrokerContactReducer from "./reducers/updateBrokerContact";
import forgotPasswordReducer from "./reducers/forgotPassword";
import setNewPasswordReducer from "./reducers/setNewPassword";
import employeesReducer from "./reducers/employees/employees";
import signupReducer from "./reducers/signup";
import { filtersReducer } from "./reducers/filters/filters";
import { sortReducer } from "./reducers/sortEmployees/sortEmployees";
import signoutReducer from "./reducers/signout";
import propertyReducer from "./reducers/property/property";
import workAreasReducer from "./reducers/workAreas";
import clientsReducer from "./reducers/clients/clients";
import notificationsReducer from "./reducers/notifications";

const store = configureStore({
  reducer: {
    employees: employeesReducer,
    clients: clientsReducer,
    signin: signinReducer,
    signup: signupReducer,
    signout: signoutReducer,
    updateAdminContact: updateAdminContactReducer,
    updateBrokerContact: updateBrokerContactReducer,
    forgotPassword: forgotPasswordReducer,
    newPassword: setNewPasswordReducer,
    nameFilter: filtersReducer,
    sortEmployees: sortReducer,
    properties: propertyReducer,
    areas: workAreasReducer,
    notifications: notificationsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
