import { SET_NAME_FILTER } from '../../actions/filters/filters';

export interface FilterState {
  lastName: string;
}

const initialState: FilterState = {
  lastName: '',
};

export const filtersReducer = (
  state = initialState,
  action: any
): FilterState => {
  switch (action.type) {
    case SET_NAME_FILTER:
      return {
        ...state,
        lastName: action.payload,
      };
    default:
      return state;
  }
};
