import { NotificationType } from "../../components/notifications/NotificationItem";
import { ADD_NOTIFICATION, SET_NOTIFICATIONS, TOGGLE_NOTIFICATIONS_DRAWER, UPDATE_NOTIFICATION } from "../actions/notifications";

interface NotificationsState {
    isNotificationsDrawerOpen: boolean;
    notifications: NotificationType[];
}

const initialState: NotificationsState = {
    isNotificationsDrawerOpen: false,
    notifications: [],
};

const notificationsReducer = (
    state = initialState,
    action: any
): NotificationsState => {
    switch (action.type) {
        case TOGGLE_NOTIFICATIONS_DRAWER:
            return {
                ...state,
                isNotificationsDrawerOpen: action.payload,
            };
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload]
            };
        case UPDATE_NOTIFICATION:
            return {
                ...state, notifications: state.notifications.map(notification => notification.id === action.payload ? { ...notification, isNew: false } : notification)
            }
        default:
            return state;
    }
};

export default notificationsReducer;
