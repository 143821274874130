import {
  emailDataProps,
  newPasswordProps,
  registeredUserDataProps,
} from "../types";

import {
  FORGOT_PASSWORD_EMAIL_FAILURE,
  FORGOT_PASSWORD_EMAIL_REQUEST,
  FORGOT_PASSWORD_EMAIL_SUCCESS,
} from "../redux/actions/forgotPassword";
import {
  SET_NEW_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
} from "../redux/actions/setNewPassword";

import {
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
} from "../redux/actions/signin";

import {
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "../redux/actions/signup";

import { AppDispatch } from "../redux/store";
import {
  SIGNOUT_FAILURE,
  SIGNOUT_REQUEST,
  SIGNOUT_SUCCESS,
} from "../redux/actions/signout";

import { tokenStorage } from "../helpers/storageFunctions";
import api from "./axiosInterceptor";
import { jwtDecode, JwtPayload } from "jwt-decode";

const DB_URL = process.env.REACT_APP_BASE_URL;

export const signupApi = ({ password, confirmPassword }: newPasswordProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: SIGNUP_REQUEST,
      });
      const response = await api.post(`${DB_URL}auth/sign-up`, {
        password,
        confirmPassword,
      });

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: SIGNUP_FAILURE,
        payload: error.message || "Failed to sign up!",
      });
    }
  };
};

export const signinApi = ({ email, password }: registeredUserDataProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: SIGNIN_REQUEST,
      });
      const response = await api.post(`${DB_URL}auth/sign-in`, {
        email,
        password,
      });

      dispatch({
        type: SIGNIN_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: SIGNIN_FAILURE,
        payload: error.message || "Failed to sign in!",
      });
    }
  };
};

export const signOutApi = (token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: SIGNOUT_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.post(`${DB_URL}auth/logout`, {}, config);

      dispatch({
        type: SIGNOUT_SUCCESS,
        payload: response.status,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: SIGNOUT_FAILURE,
        payload: error.message || "Failed to sign out!",
      });
    }
  };
};

export const forgotPasswordApi = (email: emailDataProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: FORGOT_PASSWORD_EMAIL_REQUEST,
      });

      const response = await api.post(
        `${DB_URL}auth/forgot-password/send-email`,
        email
      );

      dispatch({
        type: FORGOT_PASSWORD_EMAIL_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: FORGOT_PASSWORD_EMAIL_FAILURE,
        payload: error.message || "No such user found!",
      });
      return Promise.reject(error);
    }
  };
};

export const checkCodeApi = (email: emailDataProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: FORGOT_PASSWORD_EMAIL_REQUEST,
      });

      const response = await api.post(
        `${DB_URL}auth/forgot-password/check-code`,
        { email }
      );

      dispatch({
        type: FORGOT_PASSWORD_EMAIL_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: FORGOT_PASSWORD_EMAIL_FAILURE,
        payload: error.message || "No such user found!",
      });
    }
  };
};

export const updateUserPasswordApi = (token: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: SET_NEW_PASSWORD_REQUEST,
      });

      const requestBody = {
        token: token,
        newPassword: password,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Secret-Key": process.env.REACT_APP_UPDATE_PASSWORD_SECRET_KEY,
        },
      };

      const response = await api.post(
        `${DB_URL}auth/update-password`,
        requestBody,
        config
      );

      dispatch({
        type: SET_NEW_PASSWORD_SUCCESS,
        pauload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({
        type: SET_NEW_PASSWORD_FAILURE,
        payload: error.message || "Operation failed, please try again later!",
      });
      return Promise.reject(error);
    }
  };
};

export const refreshToken = async () => {
  const refreshToken = tokenStorage().getRefreshToken();

  const isValidToken = (token: string): boolean => {
    try {
      const decoded = jwtDecode<JwtPayload>(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return !!decoded.exp && decoded.exp > currentTime;
    } catch (error) {
      console.error("Invalid token format", error);
      return false;
    }
  };

  if (!isValidToken(refreshToken!)) {
    localStorage.clear();
  }

  const response = await api.post(`${DB_URL}auth/refresh`, {
    refreshToken,
  });
  const { accessToken } = response.data;
  tokenStorage().setAccessToken(accessToken);
  return { accessToken };
};
