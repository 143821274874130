import { Areas } from "../../types";
import { GET_WORK_AREAS_FAILURE, GET_WORK_AREAS_REQUEST, GET_WORK_AREAS_SUCCESS } from "../actions/workAreas";

interface PropertyState {
  loading: boolean;
  areas: Areas[];
  error: string | null;
}

const initialState: PropertyState = {
  loading: false,
  areas: [],
  error: null,
};

interface GetAllWorkAreasRequestAction {
  type: typeof GET_WORK_AREAS_REQUEST;
}

interface GetAllWorkAreasSuccessAction {
  type: typeof GET_WORK_AREAS_SUCCESS;
  payload: Areas[];
}

interface GetAllWorkAreasFailureAction {
  type: typeof GET_WORK_AREAS_FAILURE;
  payload: string;
}

export type PropertyActionType =
|GetAllWorkAreasRequestAction
|GetAllWorkAreasSuccessAction
|GetAllWorkAreasFailureAction

const workAreasReducer = (state = initialState, action: any): PropertyState => {
  switch (action.type) {
    case GET_WORK_AREAS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_WORK_AREAS_SUCCESS:
      return {
        ...state,
        loading: false,
        areas: action.payload,
        error: null,
      };
    case GET_WORK_AREAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default workAreasReducer;
