import { emailDataProps } from "../../types";
import {
  FORGOT_PASSWORD_EMAIL_FAILURE,
  FORGOT_PASSWORD_EMAIL_REQUEST,
  FORGOT_PASSWORD_EMAIL_SUCCESS,
} from "../actions/forgotPassword";

interface forgotPasswordEmailState {
  loading: boolean;
  email: any;
  error: string | null;
}

const initialState: forgotPasswordEmailState = {
  loading: false,
  email: null,
  error: null,
};

interface ForgotPasswordRequestAction {
  type: typeof FORGOT_PASSWORD_EMAIL_REQUEST;
}

interface ForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_EMAIL_SUCCESS;
  payload: emailDataProps;
}

interface ForgotPasswordFailureAction {
  type: typeof FORGOT_PASSWORD_EMAIL_FAILURE;
  payload: string;
}

export type SignupActionTypes =
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction;

const forgotPasswordReducer = (
  state = initialState,
  action: any
): forgotPasswordEmailState => {
  switch (action.type) {
    case FORGOT_PASSWORD_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        email: action.payload,
        error: null,
      };
    case FORGOT_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default forgotPasswordReducer;
