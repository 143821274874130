import { registeredUserDataProps } from "../../types";
import {
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
} from "../actions/signin";

interface SigninState {
  loading: boolean;
  user: any;
  error: string | null;
}

const initialState: SigninState = {
  loading: false,
  user: null,
  error: null,
};

interface SigninRequestAction {
  type: typeof SIGNIN_REQUEST;
}

interface SigninSuccessAction {
  type: typeof SIGNIN_SUCCESS;
  payload: registeredUserDataProps;
}

interface SigninFailureAction {
  type: typeof SIGNIN_FAILURE;
  payload: string;
}

export type SignupActionTypes =
  | SigninRequestAction
  | SigninSuccessAction
  | SigninFailureAction;

const signinReducer = (state = initialState, action: any): SigninState => {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default signinReducer;
