import { SvgIcon } from '@mui/material';

const EyeIcon = ({ color }: { color: string }) => (
  <svg
    width='19'
    height='14'
    viewBox='0 0 19 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Icon'>
      <path
        d='M1.81936 7.99405C1.52701 7.35803 1.52701 6.62429 1.81936 5.98828C3.17259 3.04421 6.14762 1 9.6001 1C13.0526 1 16.0276 3.04421 17.3808 5.98828C17.6732 6.6243 17.6732 7.35803 17.3808 7.99405C16.0276 10.9381 13.0526 12.9823 9.6001 12.9823C6.14762 12.9823 3.17259 10.9381 1.81936 7.99405Z'
        stroke={color}
        strokeOpacity='0.87'
        strokeWidth='1.5'
      />
      <path
        d='M12.1677 6.99116C12.1677 8.40923 11.0182 9.5588 9.6001 9.5588C8.18203 9.5588 7.03246 8.40923 7.03246 6.99116C7.03246 5.57309 8.18203 4.42352 9.6001 4.42352C11.0182 4.42352 12.1677 5.57309 12.1677 6.99116Z'
        stroke={color}
        strokeOpacity='0.87'
        strokeWidth='1.5'
      />
    </g>
  </svg>
);

export default EyeIcon;
