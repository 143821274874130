export const GET_ALL_EMPLOYEES_REQUEST = 'GET_ALL_EMPLOYEES_REQUEST'
export const GET_ALL_EMPLOYEES_SUCCESS = 'GET_ALL_EMPLOYEES_SUCCESS'
export const GET_ALL_EMPLOYEES_FAILURE = 'GET_ALL_EMPLOYEES_FAILURE'


export const GET_EMPLOYEE_BY_ID_REQUEST = "GET_EMPLOYEE_BY_ID_REQUEST"
export const GET_EMPLOYEE_BY_ID_SUCCESS = "GET_EMPLOYEE_BY_ID_SUCCESS"
export const GET_EMPLOYEE_BY_ID_FAILURE = "GET_EMPLOYEE_BY_ID_FAILURE"

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE"

export const CREATE_EMPLOYEE_REQUEST = "CREATE_EMPLOYEE_REQUEST"
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS"
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE"

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST"
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS"
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_EMPLOYEE_FAILURE"

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST"
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS"
export const DELETE_EMPLOYEE_FAILURE = "DELETE_EMPLOYEE_FAILURE"

export const UPLOAD_PHOTO_REQUEST = "UPLOAD_PHOTO_REQUEST"
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS"
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE"