import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import deleteRedIcon from "../../../svg/deleteRedIcon.svg";
interface DeleteClientPopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteClientPopup: React.FC<DeleteClientPopupProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: "24px 16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            marginInline: "auto",
            borderRadius: "100px",
            background: "#FECDD2",
          }}
        >
          <img width={15} height={18} src={deleteRedIcon} alt="delete icon" />
        </div>

        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            marginInline: "auto",
          }}
        >
          {"Delete this document?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ display: "flex", marginInline: "auto" }}>
            {`You will not be able to recover this document.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", flexDirection: "column" }}>
          <Button
            fullWidth
            onClick={onConfirm}
            sx={{
              transition: "background-color color 0.3s ease",
              background: "#D32F2F",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "#C62828",
              },
              "&:active": {
                background: "#C62828",
              },
            }}
          >
            {"Yes, Delete"}
          </Button>
          <Button fullWidth onClick={onClose} style={{ color: "#000000DE" }}>
            {"Cancel"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteClientPopup;
