import { tokenStorage } from "../../helpers/storageFunctions";
import { SIGNOUT_FAILURE, SIGNOUT_REQUEST, SIGNOUT_SUCCESS } from "../actions/signout";

const currentUser = tokenStorage().getAccessToken()

interface SignoutState {
  loading: boolean;
  user: any
  error: string | null;
}

const initialState: SignoutState = {
  loading: false,
  user: currentUser,
  error: null,
};

interface SignoutRequestAction {
  type: typeof SIGNOUT_REQUEST;
}

interface SignoutSuccessAction {
  type: typeof SIGNOUT_SUCCESS;
  payload: number
}

interface SignoutFailureAction {
  type: typeof SIGNOUT_FAILURE;
  payload: string;
}

export type SignoutActionTypes =
  | SignoutRequestAction
  | SignoutSuccessAction
  | SignoutFailureAction;

const signoutReducer = (state = initialState, action: any): SignoutState => {
  switch (action.type) {
    case SIGNOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        error: null,
      };
    case SIGNOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default signoutReducer;
