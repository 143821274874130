import { NotificationType } from "../../components/notifications/NotificationItem";

export const TOGGLE_NOTIFICATIONS_DRAWER = "TOGGLE_NOTIFICATIONS_DRAWER";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const toggleNotificationsDrawerActionCreator = (par: boolean) => ({ type: TOGGLE_NOTIFICATIONS_DRAWER, payload: par })

export const setNotifications = (notifications: NotificationType[]) => ({
    type: SET_NOTIFICATIONS,
    payload: notifications,
});

export const addNotifications = (notification: NotificationType) => ({
    type: ADD_NOTIFICATION,
    payload: notification,
});

export const updateNotification = (id: string) => ({
    type: UPDATE_NOTIFICATION,
    payload: id,
});