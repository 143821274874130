import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { Breadcrumbs, Typography } from "@mui/material";

type Params = {
  id?: string;
};

const BreadcrumbsNav: React.FC<{ firstName?: string; lastName?: string}> = ({
  firstName,
  lastName,
}) => {
  const { id } = useParams<Params>();
  const location = useLocation();

  const generateBreadcrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const breadcrumbItems = [];

    breadcrumbItems.push(
      <Link
        to="/"
        key="home"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {"Home"}
      </Link>
    );

    // /property
    if (pathnames[0] === "property") {
        breadcrumbItems.push(
          <Link
            to="/property"
            key="property"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {"Property"}
          </Link>
        );
        // /property/add-property
        if (pathnames.includes("add-property")) {
          breadcrumbItems.push(
            <Typography key="add-property" color="textPrimary">
              {"Add Property"}
            </Typography>
          );
        }
        // edit/:id page
        else if (pathnames.includes("edit")) {
          breadcrumbItems.push(
            <Typography key="edit" color="textPrimary">
              {`Edit ${firstName}`}
            </Typography>
          );
        }
        // profile page /property/:id
        else if (id && firstName) {
          breadcrumbItems.push(
            <Typography key="property-name" color="textPrimary">
              {firstName}
            </Typography>
          );
        }
      }
    // /employees
    if (pathnames[0] === "employees") {
      breadcrumbItems.push(
        <Link
          to="/employees"
          key="employees"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {"Employees"}
        </Link>
      );
      // /employees/add-employee
      if (pathnames.includes("add-employee")) {
        breadcrumbItems.push(
          <Typography key="add-employee" color="textPrimary">
            {"Add Employee"}
          </Typography>
        );
      }
      // edit/:id page
      else if (pathnames.includes("edit")) {
        breadcrumbItems.push(
          <Typography key="edit" color="textPrimary">
            {`Edit ${firstName} ${lastName}`}
          </Typography>
        );
      }
      // profile page /employees/:id
      else if (id && firstName && lastName) {
        breadcrumbItems.push(
          <Typography key="employee-name" color="textPrimary">
            {firstName} {lastName}
          </Typography>
        );
      }
    }
    // /my-profile
    if (pathnames[0] === "my-profile") {
      breadcrumbItems.push(
        <Link
          to="/my-profile"
          key="my-profile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {"My profile"}
        </Link>
      );

      if (pathnames.includes("edit")) {
        breadcrumbItems.push(
          <Typography key="edit" color="textPrimary">
            {'Edit my profile'}
          </Typography>
        );
      }
    }
    // /clients
    if (pathnames[0] === "clients") {
      breadcrumbItems.push(
        <Link to="/clients" key="clients" style={{ textDecoration: "none", color: "inherit" }}>{"Clients"}</Link>
      )

      if (pathnames.includes("add-client")) {
        breadcrumbItems.push(
          <Typography key="add-client" color="textPrimary">
            {"Add Client"}
          </Typography>
        );
      }
      // edit/:id page
      else if (pathnames.includes("edit")) {
        breadcrumbItems.push(
          <Typography key="edit" color="textPrimary">
            {`Edit ${firstName} ${lastName}`}
          </Typography>
        );
      }
      // profile page /clients/:id
      else if (id && firstName && lastName) {
        breadcrumbItems.push(
          <Typography key="client-name" color="textPrimary">
            {firstName} {lastName}
          </Typography>
        );
      }
    }

    return breadcrumbItems;
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ padding: "32px 24px" }}>
      {generateBreadcrumbs()}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
