export const GET_ALL_PROPERTIES_REQUEST = "GET_ALL_PROPERTIES_REQUEST";
export const GET_ALL_PROPERTIES_SUCCESS = "GET_ALL_PROPERTIES_SUCCESS";
export const GET_ALL_PROPERTIES_FAILURE = "GET_ALL_PROPERTIES_FAILURE";

export const GET_PROPERTY_BY_ID_REQUEST = "GET_PROPERTY_BY_ID_REQUEST";
export const GET_PROPERTY_BY_ID_SUCCESS = "GET_PROPERTY_BY_ID_SUCCESS";
export const GET_PROPERTY_BY_ID_FAILURE = "GET_PROPERTY_BY_ID_FAILURE";

export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_FAILURE = "CREATE_PROPERTY_FAILURE";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAILURE = "DELETE_PROPERTY_FAILURE";

export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_FAILURE = "UPDATE_PROPERTY_FAILURE";

export const UPLOAD_PROPERTY_DOCUMENTS_REQUEST = "UPLOAD_PROPERTY_DOCUMENTS_REQUEST"
export const UPLOAD_PROPERTY_DOCUMENTS_SUCCESS = "UPLOAD_PROPERTY_DOCUMENTS_SUCCESS"
export const UPLOAD_PROPERTY_DOCUMENTS_FAILURE = "UPLOAD_PROPERTY_DOCUMENTS_FAILURE"

export const DELETE_PROPERTY_DOCUMENTS_REQUEST = "DELETE_PROPERTY_DOCUMENTS_REQUEST"
export const DELETE_PROPERTY_DOCUMENTS_SUCCESS = "DELETE_PROPERTY_DOCUMENTS_SUCCESS"
export const DELETE_PROPERTY_DOCUMENTS_FAILURE = "DELETE_PROPERTY_DOCUMENTS_FAILURE"

export const UPLOAD_PROPERTY_IMAGE_REQUEST = "UPLOAD_PROPERTY_IMAGE_REQUEST"
export const UPLOAD_PROPERTY_IMAGE_SUCCESS = "UPLOAD_PROPERTY_IMAGE_SUCCESS"
export const UPLOAD_PROPERTY_IMAGE_FAILURE = "UPLOAD_PROPERTY_IMAGE_FAILURE"

export const DELETE_PROPERTY_IMAGE_REQUEST = "DELETE_PROPERTY_IMAGE_REQUEST"
export const DELETE_PROPERTY_IMAGE_SUCCESS = "DELETE_PROPERTY_IMAGE_SUCCESS"
export const DELETE_PROPERTY_IMAGE_FAILURE = "DELETE_PROPERTY_IMAGE_FAILURE"

export const GET_PROPERTY_COMMENTS_REQUEST = "GET_PROPERTY_COMMENTS_REQUEST"
export const GET_PROPERTY_COMMENTS_SUCCESS = "GET_PROPERTY_COMMENTS_SUCCESS"
export const GET_PROPERTY_COMMENTS_FAILURE = "GET_PROPERTY_COMMENTS_FAILURE"

export const CREATE_PROPERTY_COMMENT_REQUEST = "CREATE_PROPERTY_COMMENT_REQUEST"
export const CREATE_PROPERTY_COMMENT_SUCCESS = "CREATE_PROPERTY_COMMENT_SUCCESS"
export const CREATE_PROPERTY_COMMENT_FAILURE = "CREATE_PROPERTY_COMMENT_FAILURE"