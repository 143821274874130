import {
  UPDATE_ADMIN_CONTACT_FAILURE,
  UPDATE_ADMIN_CONTACT_REQUEST,
  UPDATE_ADMIN_CONTACT_SUCCESS,
} from "../actions/updateAdminContact";

export interface UpdatePhoneState {
  loading: boolean;
  phone: string;
  error: string | null;
}

const initialState: UpdatePhoneState = {
  loading: false,
  phone: "",
  error: null,
};

interface UpdatePhoneRequestAction {
  type: typeof UPDATE_ADMIN_CONTACT_REQUEST;
}

interface UpdatePhoneSuccessAction {
  type: typeof UPDATE_ADMIN_CONTACT_SUCCESS;
  payload: string;
}

interface UpdatePhoneFailureAction {
  type: typeof UPDATE_ADMIN_CONTACT_FAILURE;
  payload: string;
}

export type UpdateActionTypes =
  | UpdatePhoneRequestAction
  | UpdatePhoneSuccessAction
  | UpdatePhoneFailureAction;

const updateAdminContactReducer = (
  state = initialState,
  action: any
): UpdatePhoneState => {
  switch (action.type) {
    case UPDATE_ADMIN_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_ADMIN_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        phone: action.payload,
      };
    case UPDATE_ADMIN_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateAdminContactReducer;
