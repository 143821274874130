import * as Yup from "yup";

export interface MatchingProps {
  minSqft?: number;
  maxSqft?: number;
  bedrooms?: string;
  areas?: string[];
  dealType?: string;
  availability?: string;
  minPrice?: number;
  maxPrice?: number;
  type?: string;
  bathrooms?: string;
  building?: string;
  street?: string;
}

export const matchingPropertyInitialState: MatchingProps = {
  minSqft: 0,
  maxSqft: 0,
  bedrooms: "",
  areas: [],
  dealType: "",
  availability: "",
  minPrice: 0,
  maxPrice: 0,
  type: "",
  bathrooms: "",
  building: "",
  street: "",
};

export const matchingPropertyValidationSchema = Yup.object({
  bedrooms: Yup.string()
    .oneOf(
      ["ANY", "STUDIO", "1", "2", "3", "4", "5", "6", "7+"],
      "Please select a valid number of bedrooms"
    )
    .optional(),
  areas: Yup.array().optional(),
  minPrice: Yup.number()
  .nullable()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .test(
    "min-less-than-max",
    "Minimum price must be less than or equal to maximum price",
    function (value) {
      const { maxPrice } = this.parent;
      return maxPrice == null || value == null || value <= maxPrice;
    }
  ),

  maxPrice: Yup.number()
  .nullable()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .test(
    "max-greater-than-min",
    "Maximum price must be greater than or equal to minimum price",
    function (value) {
      const { minPrice } = this.parent;
      return minPrice == null || value == null || value >= minPrice;
    }
  ),
  minSqft: Yup.number()
  .nullable()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .test(
    "min-less-than-max",
    "Minimum area must be less than or equal to maximum area",
    function (value) {
      const { maxSqft } = this.parent;
      return maxSqft == null || value == null || value <= maxSqft;
    }
  ),

  maxSqft: Yup.number()
  .nullable()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .test(
    "max-greater-than-min",
    "Maximum area must be greater than or equal to minimum area",
    function (value) {
      const { minSqft } = this.parent;
      return minSqft == null || value == null || value >= minSqft;
    }
  ),
  type: Yup.string().nullable().optional(),
  bathrooms: Yup.string()
    .oneOf(
      ["ANY", "1", "2", "3", "4", "5", "6", "7+"],
      "Please select a valid number of bathrooms"
    )
    .optional(),
  building: Yup.string().nullable().optional(),
  street: Yup.string().nullable().optional(),
});
