import { AppDispatch } from "../redux/store";
import {
  CREATE_PROPERTY_COMMENT_FAILURE,
  CREATE_PROPERTY_COMMENT_REQUEST,
  CREATE_PROPERTY_COMMENT_SUCCESS,
  CREATE_PROPERTY_FAILURE,
  CREATE_PROPERTY_REQUEST,
  CREATE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_DOCUMENTS_FAILURE,
  DELETE_PROPERTY_DOCUMENTS_REQUEST,
  DELETE_PROPERTY_DOCUMENTS_SUCCESS,
  DELETE_PROPERTY_FAILURE,
  DELETE_PROPERTY_IMAGE_FAILURE,
  DELETE_PROPERTY_IMAGE_REQUEST,
  DELETE_PROPERTY_IMAGE_SUCCESS,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  GET_ALL_PROPERTIES_FAILURE,
  GET_ALL_PROPERTIES_REQUEST,
  GET_ALL_PROPERTIES_SUCCESS,
  GET_PROPERTY_BY_ID_FAILURE,
  GET_PROPERTY_BY_ID_REQUEST,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_COMMENTS_FAILURE,
  GET_PROPERTY_COMMENTS_REQUEST,
  GET_PROPERTY_COMMENTS_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPLOAD_PROPERTY_DOCUMENTS_FAILURE,
  UPLOAD_PROPERTY_DOCUMENTS_REQUEST,
  UPLOAD_PROPERTY_DOCUMENTS_SUCCESS,
  UPLOAD_PROPERTY_IMAGE_FAILURE,
  UPLOAD_PROPERTY_IMAGE_REQUEST,
  UPLOAD_PROPERTY_IMAGE_SUCCESS,
} from "../redux/actions/property/property";
import api from "./axiosInterceptor";

const DB_URL = process.env.REACT_APP_BASE_URL;

type idType = {
  propertyId: string | undefined;
};

export const getAllPropertiesApi = (
  token: string,
  page: number,
  limit: number,
  sortBy?: string,
  sortOrder?: string,
  name?: string,
  location?: string[],
  propertyTypes?: string[],
  actionType?: string,
  status?: string,
  areaRange?: [number, number],
  priceRange?: [number, number],
  bedrooms?: string[],
  bathrooms?: string[],
  brokerId?: number,
  ownerId?: number
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_ALL_PROPERTIES_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          limit,
          sortBy,
          sortOrder,
          name: !name ? null : name,
          districts: !location ? null : location,
          buildingType: !propertyTypes ? null : propertyTypes,
          dealType: actionType === "" ? null : actionType,
          status: status === "" ? null : status,
          areaMin: areaRange && areaRange[0] > 0 ? areaRange[0] : null,
          areaMax: areaRange && areaRange[1] > 0 ? areaRange[1] : null,
          priceMin: priceRange && priceRange[0] > 0 ? priceRange[0] : null,
          priceMax: priceRange && priceRange[1] > 0 ? priceRange[1] : null,
          bedrooms: !bedrooms ? null : bedrooms,
          bathrooms: !bathrooms ? null : bathrooms,
          brokerId: brokerId,
          ownerId: ownerId,
        },
      };

      const response = await api.get(`${DB_URL}properties`, config);

      dispatch({
        type: GET_ALL_PROPERTIES_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_ALL_PROPERTIES_FAILURE,
        payload: error.message || "Failed to fetch properties!",
      });
    }
  };
};

export const getPropertyByIdApi = (id: any, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_PROPERTY_BY_ID_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.get(`${DB_URL}properties/${id}`, config);
      dispatch({
        type: GET_PROPERTY_BY_ID_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_PROPERTY_BY_ID_FAILURE,
        payload: error.message || "Failed to find property!",
      });
    }
  };
};

export const createProperty = (
  propertyData: any,
  // propertyData: PropertyInitialValues,
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: CREATE_PROPERTY_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.post(
        `${DB_URL}properties/create`,
        propertyData,
        config
      );

      dispatch({
        type: CREATE_PROPERTY_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: CREATE_PROPERTY_FAILURE,
        payload: error.message || "Failed to create property!",
      });
    }
  };
};

export const updateProperty = (id: string, values: any, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: UPDATE_PROPERTY_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.put(
        `${DB_URL}properties/${id}`,
        values,
        config
      );

      dispatch({
        type: UPDATE_PROPERTY_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: UPDATE_PROPERTY_FAILURE,
        payload: error.message || "Failed to update property!",
      });
    }
  };
};

export const deleteProperty = ({ propertyId }: idType, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DELETE_PROPERTY_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.delete(
        `${DB_URL}properties/${propertyId}`,
        config
      );

      dispatch({
        type: DELETE_PROPERTY_SUCCESS,
        payload: response.statusText,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: DELETE_PROPERTY_FAILURE,
        payload: error.message || "Failed to create employee!",
      });
    }
  };
};

export const uploadPropertyImage = (id: any, files: File[], token: string) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: UPLOAD_PROPERTY_IMAGE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await api.post(
        `${DB_URL}properties/upload-image/${id}`,
        formData,
        config
      );

      dispatch({
        type: UPLOAD_PROPERTY_IMAGE_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: UPLOAD_PROPERTY_IMAGE_FAILURE,
        payload: error.message || "Failure to upload images!",
      });
    }
  };
};

export const deletePropertyImage = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DELETE_PROPERTY_IMAGE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.delete(
        `${DB_URL}properties/delete-image/${id}`,
        config
      );

      dispatch({
        type: DELETE_PROPERTY_IMAGE_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: DELETE_PROPERTY_IMAGE_FAILURE,
        payload: error.message || "Failed to delete image!",
      });
    }
  };
};

export const uploadPropertyDocument = (
  id: any,
  documents: File[],
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: UPLOAD_PROPERTY_DOCUMENTS_REQUEST,
      });

      const formData = new FormData();
      documents.forEach((document) => {
        formData.append("files", document);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await api.post(
        `${DB_URL}properties/upload-document/${id}`,
        formData,
        config
      );

      dispatch({
        type: UPLOAD_PROPERTY_DOCUMENTS_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: UPLOAD_PROPERTY_DOCUMENTS_FAILURE,
        payload: error.message || "Failure to upload documents!",
      });
    }
  };
};

export const deletePropertyDocument = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DELETE_PROPERTY_DOCUMENTS_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.delete(
        `${DB_URL}properties/delete-document/${id}`,
        config
      );

      dispatch({
        type: DELETE_PROPERTY_DOCUMENTS_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: DELETE_PROPERTY_DOCUMENTS_FAILURE,
        payload: error.message || "Failure deleting documents!",
      });
    }
  };
};

export const getPropertyComments = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_PROPERTY_COMMENTS_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get(
        `${DB_URL}comments/propertyComments/${id}`,
        config
      );
      dispatch({
        type: GET_PROPERTY_COMMENTS_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_PROPERTY_COMMENTS_FAILURE,
        payload: error.message || "Failed to fetch documents!",
      });
    }
  };
};

export const createPropertyComment = (
  message: string,
  targetId: string,
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: CREATE_PROPERTY_COMMENT_REQUEST,
      });

      const requestBody = {
        message,
        targetId,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.post(
        `${DB_URL}comments/property/create`,
        requestBody,
        config
      );

      dispatch({
        type: CREATE_PROPERTY_COMMENT_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: CREATE_PROPERTY_COMMENT_FAILURE,
        payload: error.message || "Failed to fetch documents!",
      });
    }
  };
};
