/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { useFormik } from "formik";

import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { EventFormValues, initialValues, validationSchema } from "./config";
import { useEffect, useState } from "react";
import GoogleLoginButton from "../../googleLoginButton.tsx/GoogleLoginButton";
import { tokenStorage } from "../../../helpers/storageFunctions";
import { createGoogleCalendarEvent } from "../../../api/googleApiService";
import underDevelopment from "../../../svg/under-development.svg";

interface CreateNewEventProps {
  clientEmail: any;
  setNewEventAction: (value: boolean) => void;
}

const CreateNewEvent: React.FC<CreateNewEventProps> = ({
  clientEmail,
  setNewEventAction,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [googleTokenAuth, setGoogleTokenAuth] = useState<string | null>(
    tokenStorage().getGoogleAccessToken() || null
  );
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [AvoidEmailError, setAvoidEmailError] = useState<boolean>(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formattedDate = dayjs(values.date).format("MM-DD-YYYY");
      const formattedTime = dayjs(values.time).format("HH:mm");

      const newEvent = {
        ...values,
        date: formattedDate,
        time: formattedTime,
        clientEmail: clientEmail,
      };

      createEvent(newEvent);
      formik.resetForm();
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (clientEmail) {
      formik.setFieldValue("clientEmail", clientEmail);
    }
  }, [clientEmail, AvoidEmailError]);

  // const clietnEmailError = formik.errors["clientEmail"];

  useEffect(() => {
    if (formik.errors["clientEmail"]) {
      setSnackbarMessage("Client must have an email!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [formik.errors["clientEmail"]]);

  const createEvent = async (newEvent: EventFormValues) => {
    const token = tokenStorage().getGoogleAccessToken();
    if (!token) return;

    setAvoidEmailError(false);
    setNewEventAction(false);

    const startDateTime = dayjs(
      `${newEvent.date} ${newEvent.time}`,
      "MM-DD-YYYY HH:mm"
    );
    const startFormatted = startDateTime.format("YYYY-MM-DDTHH:mm:ssZ");

    const endDateTime = startDateTime.add(1, "hour");
    const endFormatted = endDateTime.format("YYYY-MM-DDTHH:mm:ssZ");

    const event = {
      summary: newEvent.title,
      description: newEvent.description,
      start: {
        dateTime: startFormatted,
        timeZone: "Asia/Dubai",
      },
      end: {
        dateTime: endFormatted,
        timeZone: "Asia/Dubai",
      },
      attendees: [{ email: newEvent.clientEmail }],
    };

    try {
      const response = await createGoogleCalendarEvent(event);
      if (response) {
        setSnackbarMessage("Event created successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setAvoidEmailError(true);
        setNewEventAction(true);
      }
    } catch (error) {
      setSnackbarMessage("Something went wrong!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const { values } = formik;
    const { title, description, date, time } = values;

    if (title === "" || description === "" || date === null || time === null) {
      setButtonDisabled(true);
    } else setButtonDisabled(false);
  }, [formik]);

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#f0f0f0",
        opacity: 0.6,
        pointerEvents: "none",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: 1,
        },
      }}
    >
      {googleTokenAuth ? (
        <Box sx={{ background: "#FFF", borderRadius: "6px", padding: "16px" }}>
          <Typography
            sx={{
              color: "#000000DE",
              fontSize: "20px",
              fontWeight: 500,
              marginBottom: "24px",
            }}
          >
            {"Create a new event"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  fullWidth
                  name="title"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  name="description"
                  rows={5}
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date *"
                    value={formik.values.date}
                    onChange={(value) => formik.setFieldValue("date", value)}
                    slots={{
                      textField: TextField,
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(
                          formik.touched.date && formik.errors.date
                        ),
                        helperText: Boolean(
                          formik.touched.date && formik.errors.date
                        ),
                        InputLabelProps: { shrink: true },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Time *"
                    value={dayjs(formik.values.time)}
                    onChange={(value) => formik.setFieldValue("time", value)}
                    slots={{
                      textField: TextField,
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: Boolean(
                          formik.touched.time && formik.errors.time
                        ),
                        helperText: Boolean(
                          formik.touched.time && formik.errors.time
                        ),
                        InputLabelProps: { shrink: true },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}
            >
              <Button
                type="button"
                sx={{ mt: 2, background: "#FFF", color: "black" }}
                onClick={() => formik.resetForm()}
              >
                {"CANCEL"}
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                  transition: "background-color color 0.3s ease",
                  "&:disabled": {
                    backgroundColor: "#00000030",
                    color: "grey",
                  },
                  "&:not(:disabled)": {
                    "&:hover": {
                      backgroundColor: "#673AB7",
                      color: "white",
                    },
                    "&:active": {
                      backgroundColor: "#5E35B1",
                      color: "white",
                    },
                  },
                  "&": {
                    background: "#7E57C2",
                    color: "white",
                  },
                }}
                disabled={buttonDisabled}
              >
                {"SAVE EVENT"}
              </Button>
            </Box>
          </form>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            padding: "24px",
            margin: "108px 24px",
          }}
        >
          <Box
            component="img"
            src={underDevelopment}
            alt="Under Development"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "150px",
              height: "150px",
              zIndex: 2,
            }}
          />
          <h3
            style={{
              fontSize: "24px",
              fontWeight: 400,
              color: "#000",
            }}
          >
            {"You are not logged into Google!"}
          </h3>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              color: "#00000099",
            }}
          >
            {
              "In order to be able to create an event please login into your Google account."
            }
          </p>
          <GoogleLoginButton setGoogleTokenAuth={setGoogleTokenAuth} />
        </Box>
      )}
    </Box>
  );
};

export default CreateNewEvent;
