/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import ClientProfile from "./components/clientsSection/clientProfile/ClientProfile";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Layout = lazy(() => import("./components/layout/Layout"));
const PrivateRoute = lazy(
  () => import("./components/privateRoute/PrivateRoute")
);
const PublicRoute = lazy(() => import("./components/publicRoute/PublicRoute"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const SignUp = lazy(() => import("./pages/signUp/SignUp"));
const SignIn = lazy(() => import("./pages/signIn/SignIn"));
const ForgotPassword = lazy(
  () => import("./pages/forgotPassword/ForgotPassword")
);
const SetNewPassword = lazy(
  () => import("./pages/forgotPassword/SetNewPassword")
);
const EndProccess = lazy(() => import("./pages/forgotPassword/EndProccess"));
const Employees = lazy(() => import("./pages/employees/Employees"));
const AddEmployee = lazy(() => import("./pages/employees/AddEmployee"));
const EmployeesProfile = lazy(
  () => import("./components/employeesSection/employeeProfile/EmployeeProfile")
);
const EditEmployee = lazy(() => import("./pages/employees/EditEmployee"));
const MyProfile = lazy(() => import("./components/myProfile/MyProfile"));
const EditMyProfile = lazy(
  () => import("./components/myProfile/EditMyProfile")
);
const Property = lazy(() => import("./pages/property/Property"));
const PropertyProfile = lazy(
  () => import("./components/propertySection/propertyProfile/PropertyProfile")
);
const AddProperty = lazy(() => import("./pages/property/AddProperty"));
const EditPropertyProfile = lazy(
  () => import("./pages/property/EditPropertyProfile")
);

const Clients = lazy(() => import("./pages/clients/Clients"));

const App: React.FC = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [signup] = useState<boolean>(true)

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

  return (
    <GoogleOAuthProvider clientId={clientId}>
    <Router>
      <div className="App">
        <Suspense
          fallback={
            <div>
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route element={<PublicRoute isLoggedIn={isUserLoggedIn} />}>
              <Route
                path="/"
                element={<SignIn setIsUserLoggedIn={setIsUserLoggedIn} />}
              />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/forgot-password/new-password"
                element={<SetNewPassword />}
              />
              <Route
                path="/forgot-password/success"
                element={<EndProccess icon="" header="" underHeader="" signup={signup} />}
              />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isLoggedIn={isUserLoggedIn}
                  setIsUserLoggedIn={setIsUserLoggedIn}
                />
              }
            >
              <Route
                path="/dashboard"
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path="/my-profile"
                element={
                  <Layout>
                    <MyProfile />
                  </Layout>
                }
              />
              <Route
                path="/my-profile/edit"
                element={
                  <Layout>
                    <EditMyProfile />
                  </Layout>
                }
              />
              <Route
                path="/employees"
                element={
                  <Layout>
                    <Employees />
                  </Layout>
                }
              />
              <Route
                path="/employees/:id"
                element={
                  <Layout>
                    <EmployeesProfile />
                  </Layout>
                }
              />
              <Route
                path="/employees/add-employee"
                element={
                  <Layout>
                    <AddEmployee />
                  </Layout>
                }
              />
              <Route
                path="/employees/edit/:id"
                element={
                  <Layout>
                    <EditEmployee />
                  </Layout>
                }
              />
              <Route
                path="/property"
                element={
                  <Layout>
                    <Property />
                  </Layout>
                }
              />
              <Route
                path="/property/:id"
                element={
                  <Layout>
                    <PropertyProfile />
                  </Layout>
                }
              />
              <Route
                path="/property/add-property"
                element={
                  <Layout>
                    <AddProperty />
                  </Layout>
                }
              />

              <Route
                path="/property/add-property"
                element={
                  <Layout>
                    <AddProperty />
                  </Layout>
                }
              />

              <Route
                path="/property/edit/:id"
                element={
                  <Layout>
                    <EditPropertyProfile />
                  </Layout>
                }
              />
              <Route
                path="/clients"
                element={
                  <Layout>
                    <Clients />
                  </Layout>
                }
              />
              <Route
                path="/clients/:id"
                element={
                  <Layout>
                    <ClientProfile />
                  </Layout>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
