/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Client, Employee } from "../../../../types";
import EyeIcon from "../../../../svg/eyeIcon-2";
import { formatBudget } from "../../../../helpers/formatBudget";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { tokenStorage } from "../../../../helpers/storageFunctions";
import { getCustomerActivity } from "../../../../api/clientsApi";
import { formatDate } from "../../../../helpers/formatDate";
import { normalizeCapitalization } from "../../../../helpers/normalizeCapitalization";

interface ClientInformationProps {
  clientData: Client | null;
}

interface Activity {
  id: number;
  description: string;
  client: Client;
  employee: Employee;
  comment?: Comment | null;
  createdAt: Date;
}

const ClientInformation: React.FC<ClientInformationProps> = ({
  clientData,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [color, setColor] = useState("#00000099");
  const [activities, setActivities] = useState<Activity[]>([]);
  const [displayEmail, setDisplayEmail] = useState(clientData?.email);

  const dispatch: AppDispatch = useDispatch();
  const token = tokenStorage().getAccessToken();

  const handleMouseEnter = () => {
    setColor("#7E57C2");
  };

  const handleMouseLeave = () => {
    setColor("#00000099");
  };

  const handleToggle = () => {
    setShowMore((prev) => !prev);
  };

  const fetchActivities = async () => {
    if (token && clientData?.id) {
      try {
        const response = await dispatch(
          getCustomerActivity(clientData?.id, token)
        );

        if (response?.status === 200) {
          setActivities(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [dispatch, token, clientData?.phone]);

  const phoneChanges = activities
    .filter((activity) => activity.description.includes("phone number"))
    .map((activity) => {
      const oldPhone = activity.description.match(/from (\+\d+) to/);
      return oldPhone ? oldPhone[1] : null;
    })
    .filter(Boolean)
    .slice(-3);

  useEffect(() => {
    if (!clientData?.email) {
      return;
    }
    const shortenedEmail = clientData?.email.split("@");
    setDisplayEmail(`${shortenedEmail[0].slice(0, 5)}...@${shortenedEmail[1]}`);
  }, [clientData?.email]);

  return (
    <Box
      sx={{
        background: "#FFF",
        borderRadius: "6px",
        minWidth: "300px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 16px",
        }}
      >
        <Typography>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
            {"Full Name"}
          </p>
          <p
            style={{ fontWeight: "400", fontSize: "16px", color: "#000000DE" }}
          >
            {clientData?.fullName}
          </p>
        </Typography>
        <Button
          sx={{
            color: "#00000099",
            display: "flex",
            gap: "4px",
            transition: "color 0.3s",
            "&:hover": {
              color: "#7E57C2",
              background: "transparent",
            },
          }}
          onClick={handleToggle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {showMore ? "See Less" : "See More"}
          <EyeIcon color={color} />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "488px",
          padding: "8px 16px",
        }}
      >
        <Typography sx={{ width: "50%" }}>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
            {"Email"}
          </p>
          <Tooltip
            title={clientData?.email}
            componentsProps={{
              arrow: {
                sx: {
                  color: "white",
                },
              },
              tooltip: {
                sx: {
                  color: "#7E57C2",
                  backgroundColor: "white",
                  fontSize: "12px",
                  padding: "8px",
                  boxShadow:
                    "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                },
              },
            }}
            placement="top-start"
            arrow
          >
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#000000DE",
              }}
            >
              {displayEmail}
            </p>
          </Tooltip>
        </Typography>
        <Typography sx={{ width: "50%" }}>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
            {"Phone"}
          </p>
          <Tooltip
            title={
              phoneChanges.length > 0 ? (
                <div>
                  {phoneChanges.map((phone, index) => (
                    <div key={index}>{phone}</div>
                  ))}
                </div>
              ) : (
                "No previous phone numbers"
              )
            }
            componentsProps={{
              arrow: {
                sx: {
                  color: "white",
                },
              },
              tooltip: {
                sx: {
                  color: "#7E57C2",
                  backgroundColor: "white",
                  fontSize: "12px",
                  padding: "8px",
                  boxShadow:
                    "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                },
              },
            }}
            placement="top-start"
            arrow
          >
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#000000DE",
              }}
            >
              {clientData?.phone}
            </p>
          </Tooltip>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "488px",
          padding: "8px 16px",
        }}
      >
        <Typography sx={{ width: "50%" }}>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
            {"Budget"}
          </p>
          <p
            style={{ fontWeight: "400", fontSize: "16px", color: "#000000DE" }}
          >
            {`${clientData?.budget && formatBudget(clientData.budget)} AED`}
          </p>
        </Typography>
        <Typography sx={{ width: "50%" }}>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
            {"Area"}
          </p>
          <p
            style={{ fontWeight: "400", fontSize: "16px", color: "#000000DE" }}
          >
            {clientData?.areas?.join(", ")}
          </p>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "488px",
          padding: "8px 16px",
        }}
      >
        <Typography sx={{ width: "50%" }}>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
            {"Bedrooms"}
          </p>
          <p
            style={{ fontWeight: "400", fontSize: "16px", color: "#000000DE" }}
          >
            {clientData?.bedrooms &&
              normalizeCapitalization(clientData?.bedrooms)}
          </p>
        </Typography>
        <Typography sx={{ width: "50%" }}>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
            {"Availability"}
          </p>
          <p
            style={{ fontWeight: "400", fontSize: "16px", color: "#000000DE" }}
          >
            {clientData?.availability}
          </p>
        </Typography>
      </Box>

      {showMore && (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "488px",
              padding: "8px 16px",
            }}
          >
            <Typography sx={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#00000099",
                }}
              >
                {"Birthday"}
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#000000DE",
                }}
              >
                {clientData?.birthday ? formatDate(clientData?.birthday) : null}
              </p>
            </Typography>
            <Typography sx={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#00000099",
                }}
              >
                {"WhatsApp link"}
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#000000DE",
                }}
              >
                {clientData?.whatsAppLink ?? null}
              </p>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "488px",
              padding: "8px 16px",
            }}
          >
            <Typography sx={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#00000099",
                }}
              >
                {"Home address"}
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#000000DE",
                }}
              >
                {clientData?.address ?? null}
              </p>
            </Typography>
            <Typography sx={{ width: "50%" }}></Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ClientInformation;
