import * as Yup from "yup";

export interface EventFormValues {
  title: string;
  description: string;
  date: any;
  time: any;
  clientEmail: string;
}

export const initialValues: EventFormValues = {
  title: "",
  description: "",
  date: null,
  time: null,
  clientEmail: "",
};

export const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  date: Yup.date().nullable().required("Date is required"),
  time: Yup.date().nullable().required("Time is required"),
  clientEmail: Yup.string()
    .email("Invalid email format")
    .required("Client must have an email!"),
});
