import dayjs from "dayjs";
import api from "./axiosInterceptor";
import { tokenStorage } from "../helpers/storageFunctions";

const DB_URL = process.env.REACT_APP_BASE_URL;

export const createGoogleCalendarEvent = async (event: any) => {
  try {
    const { data } = await api.post(
      "https://www.googleapis.com/calendar/v3/calendars/primary/events",
      event
    );

    const localDbResponse = await api.post(`${DB_URL}events/create`, event);

    return { data, localDbResponse };
  } catch (error) {
    throw error;
  }
};

export const getGoogleCalendarEvents = async () => {
  const token = tokenStorage().getGoogleAccessToken();
  if (token) {
    const startOfDay = dayjs().startOf("day").toISOString();
    const endOfDay = dayjs().endOf("day").toISOString();

    try {
      const response = await api.get(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          params: {
            timeMin: startOfDay,
            timeMax: endOfDay,
            singleEvents: true,
            orderBy: "startTime",
          },
        }
      );

      return response.data.items;
    } catch (error) {
      console.error("Error fetching Google Calendar events:", error);
      return [];
    }
  }
};

export const deleteGoogleCalendarEvent = async (eventId: string) => {
  try {
    const response = await api.delete(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export async function googleLogin(codeResponse: any) {
  if (codeResponse) {
    try {
      const response = await api.post(`${DB_URL}auth/google-login`, {
        code: codeResponse.code,
      });

      tokenStorage().setGoogleAccessToken(response.data.access_token);
      tokenStorage().setGoogleRefreshAccessToken(response.data.refresh_token);

      return response.data.access_token;
    } catch (error) {
      console.error("Error exchanging code for tokens:", error);
    }
  }
}

export async function refreshGoogleAccessToken() {
  const refreshToken = tokenStorage().getGoogleRefreshAccessToken();
  const response = await api.post(`${DB_URL}auth/google-refresh`, {
    refreshToken,
  });

  const { access_token } = response.data;
  tokenStorage().setGoogleAccessToken(access_token);
  return { access_token };
}
