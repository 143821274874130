import React from "react";

import { Button } from "@mui/material";

import style from "./EmptyPage.module.css";

type EmptyPageType = {
  srcIcon: string;
  altDescription: string;
  headerTitle: string;
  underHeaderTitle: string;
  handleClick?: (event: any) => void;
  buttonTitle?: string;
};

const EmptyPage: React.FC<EmptyPageType> = ({
  srcIcon,
  altDescription,
  headerTitle,
  underHeaderTitle,
  handleClick,
  buttonTitle,
}) => {
  return (
    <div className={style.container}>
      <div className={style.icon}>
        <img width={24} height={24} src={srcIcon} alt={altDescription} />
      </div>
      <h2 className={style.header}>{headerTitle}</h2>
      <p className={style.underHeader}>{underHeaderTitle}</p>
      {buttonTitle && (
        <Button
          fullWidth
          sx={{
            paddingInline: "16px",
            transition: "background-color color 0.3s ease",
            "&:disabled": {
              backgroundColor: "#00000030",
              color: "grey",
            },
            "&:not(:disabled)": {
              "&:hover": {
                backgroundColor: "#673AB7",
                color: "white",
              },
              "&:active": {
                backgroundColor: "#5E35B1",
                color: "white",
              },
            },
            "&": {
              background: "#7E57C2",
              color: "white",
              boxShadow: "0px 3px 2px #00000033",
            },
          }}
          onClick={handleClick}
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};

export default EmptyPage;
