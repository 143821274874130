/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";

import {
  ClientInitialData,
  ClientsEditInitialDataProps,
  validationSchema,
} from "./editClientMenu/config";
import { AppDispatch } from "../../../redux/store";
import PageHeader from "../../pageHeader/PageHeader";
import ActivityFeed from "./activityFeed/ActivityFeed";
import EditClientMenu from "./editClientMenu/EditClientMenu";
import { getAllPropertiesApi } from "../../../api/propertiesApi";
import { tokenStorage } from "../../../helpers/storageFunctions";
import employeesPlaceholder from "../../../svg/employeesIcon.svg";
import { Areas, Client, Employee, Property } from "../../../types";
import {
  getClientById,
  getClientDocuments,
  updateClient,
} from "../../../api/clientsApi";
import bedroomLogo from "../../../svg/bedroom.svg";
import { getCurrentUser } from "../../../api/employeesApi";
import ListingAgentCard from "./listingAgentCard/ListingAgentCard";
import PropertyDocuments from "../propertyDocuments/PropertyDocuments";
import MatchingProperties from "../matchingProperties/MatchingProperties";
import BreadcrumbsNav from "../../../shared/breadCrumbsNav/BreadCrumbsNav";
import whatsAppIcon from "../../../svg/whatsappIcon.svg";
import whatsAppIconDisabled from "../../../svg/whatsAppIconDisabled.svg";

export interface Documents {
  id: string;
  fileName: string;
  key: string;
  size: number;
  mimeType: string;
  url: string;
  createdAt: string;
  updatedAt: string;
}

const ClientProfile = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [clientData, setClientData] = useState<Client>(ClientInitialData);
  const [page] = useState(1);
  const [limit] = useState(4);
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [brokerData, setBrokerData] = useState<Employee | null>(null);
  const [currentUser, setCurrentUser] = useState<Employee | null>(null);
  const [property, setProperty] = useState<Property[]>([]);
  const [documents, setDocuments] = useState<Documents[] | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (property.length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const token = tokenStorage().getAccessToken();
  const dispatch: AppDispatch = useDispatch();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const token = tokenStorage().getAccessToken();

      if (!token) {
        return;
      }

      try {
        const result = await dispatch(getCurrentUser(token));
        if (result?.status === 200) {
          setCurrentUser(result.data);
        }
      } catch (error) {
        console.error("Error fetching employee:", error);
      }
    };

    fetchCurrentUser();
  }, []);

  const fetchClient = async () => {
    try {
      if (token && id) {
        const response = await dispatch(getClientById(id, token));

        if (response?.status === 200) {
          const area = response.data.areas.map((area: Areas) => area.name);

          const formattedData = {
            ...response.data,
            areas: area,
            broker:
              response.data.broker &&
              `${response.data.broker.firstName} ${response.data.broker.lastName}`,
            brokerId: response.data.broker && response.data.broker.id,
          };
          setClientData(formattedData);
          setBrokerData(response.data.broker);
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    fetchClient();
  }, [dispatch, token]);

  const handleSubmitClientData = async (
    values: ClientsEditInitialDataProps
  ) => {
    if (id && values && token) {
      try {
        values.email =
          values.email === clientData.email ? undefined : values.email;
        values.address = values.address === "" ? undefined : values.address;

        const response = await dispatch(updateClient(id, values, token));

        if (response?.status === 400) {
          const errorMessage =
            response.data?.message || "Invalid data provided!";
          setSnackbarMessage(errorMessage);
          setSnackbarOpen(true);
        }
        if (response?.status === 200) {
          setSnackbarMessage("New changes have been successfully saved!");
          setSnackbarOpen(true);
          setTimeout(() => {
            setOpen(false);
            setSnackbarOpen(false);
          }, 1000);
          await fetchClient();
        }
      } catch (error) {
        console.error("Error creating client:", error);
        alert("An error occurred while creating the client.");
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: clientData,
    validationSchema: validationSchema,
    onSubmit: handleSubmitClientData,
    validateOnMount: true,
  });

  const clientFirtName = clientData.fullName.split(" ")[0];
  const clientLastName = clientData.fullName.split(" ")[1];

  useEffect(() => {
    const fetchProperties = async () => {
      if (token && clientData.id) {
        try {
          const result = await dispatch(
            getAllPropertiesApi(
              token,
              page,
              limit,
              "createdAt",
              "DESC",
              undefined,
              undefined,
              undefined,
              "",
              "",
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              +clientData.id
            )
          );

          if (result?.status === 200) {
            setProperty(result.data.data);
          }
        } catch (error) {
          console.error("Error fetching properties:", error);
        }
      }
    };

    fetchProperties();
  }, [dispatch, token, page, limit, clientData]);

  const navigate = useNavigate();

  const handlePropertyOnClick = (property: Property) => {
    navigate(`/property/${property.id}`);
  };

  const fetchDocuments = async () => {
    if (token && clientData.id) {
      const response = await dispatch(getClientDocuments(clientData.id, token));
      if (response?.status === 200) {
        setDocuments(response.data);
      }
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [dispatch, token, clientData.id]);

  return (
    <>
      <PageHeader />
      <BreadcrumbsNav firstName={clientFirtName} lastName={clientLastName} />
      <Box sx={{ padding: "32px 24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "32px",
            background: "#FFF",
            borderRadius: "6px",
            minWidth: "720px",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <h2
                style={{
                  fontWeight: 400,
                  fontSize: "48px",
                  color: "#000000DE",
                }}
              >
                {clientData.fullName}
              </h2>
              <EditClientMenu
                onSubmit={handleSubmitClientData}
                formik={formik}
                open={open}
                setOpen={setOpen}
                currentUser={currentUser}
              />
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "8px",
              }}
            >
              <p
                style={{
                  color: "#00000099",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {clientData.type?.map((type) => {
                  return <p key={type}>{type}</p>;
                })}
              </p>
              <p
                style={{
                  padding: "3px 10px",
                  border: "1px solid green",
                  borderRadius: "100px",
                  color: "green",
                }}
              >
                {clientData?.status}
              </p>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "16px",
              }}
            >
              <Button
                href={clientData?.whatsAppLink || ""}
                target={clientData?.whatsAppLink ? "_blank" : undefined}
                rel={
                  clientData?.whatsAppLink ? "noopener noreferrer" : undefined
                }
                disabled={!clientData.whatsAppLink}
                sx={{
                  "&": {
                    border: "1px solid #7E57C2",
                    cursor: clientData.whatsAppLink ? "pointer" : "default",
                    transition: "background-color 0.3s",
                    padding: "6px 27px",
                    width: "175px",
                    pointerEvents: clientData.whatsAppLink ? "auto" : "none",
                    color: "#7E57C2",
                  },
                  "&:not(:disabled):hover": {
                    backgroundColor: "#EDE7F6",
                  },
                  "&:disabled": {
                    borderColor: "#00000061",
                    cursor: "default",
                  },
                }}
              >
                <img
                  alt="WhatsApp icon"
                  src={
                    clientData.whatsAppLink
                      ? whatsAppIcon
                      : whatsAppIconDisabled
                  }
                  style={{ marginRight: "10px", width: "20px", height: "20px" }}
                />
                {"WHATS APP"}
              </Button>
              <Button
                onClick={handleOpenMenu}
                sx={{
                  padding: "7px 29px",
                  width: "175px",
                  transition: "background-color color 0.3s ease",
                  "&:disabled": {
                    backgroundColor: "#00000030",
                    color: "grey",
                  },
                  "&:not(:disabled)": {
                    "&:hover": {
                      backgroundColor: "#673AB7",
                      color: "white",
                    },
                    "&:active": {
                      backgroundColor: "#5E35B1",
                      color: "white",
                    },
                  },
                  "&": {
                    background: "#7E57C2",
                    color: "white",
                  },
                }}
              >{`PROPERTIES (${property.length})`}</Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    minWidth: "300px",
                    boxShadow:
                      "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                    borderRadius: "4px",
                  },
                }}
              >
                {property.map((prop) => (
                  <>
                    <MenuItem
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        gap: "24px",
                      }}
                      key={prop.id}
                      onClick={() => handlePropertyOnClick(prop)}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        {prop.name}
                      </span>
                      <Typography
                        variant="body2"
                        display={"flex"}
                        alignItems={"center"}
                        gap={"8px"}
                      >
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={bedroomLogo}
                          alt="area pic"
                        />
                        {prop.bedrooms}
                      </Typography>
                    </MenuItem>
                    <Divider sx={{ width: "100%", height: "2px" }} />
                  </>
                ))}
              </Menu>
            </Box>
          </Box>
          {clientData?.broker ? (
            <ListingAgentCard clientBroker={brokerData} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: 2,
                width: "200px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt={"employeesPlaceholder"}
                  src={employeesPlaceholder}
                  sx={{
                    width: "40px",
                    height: "40px",
                    marginRight: "8px",
                    background: "grey",
                  }}
                />
                <Typography>{"Not assigned"}</Typography>
              </div>
            </Box>
          )}
        </Box>
        <Box sx={{ width: "100%", marginTop: "32px" }}>
          <Tabs
            sx={{
              "& .MuiTabs-flexContainer": {
                display: "flex",
                alignItems: "center",
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#7E57C2",
                height: "2px",
              },
            }}
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab
              sx={{
                color: "#00000099",
                "&.Mui-selected": {
                  color: "#7E57C2",
                },
              }}
              label="Activity Feed"
            />
            <Tab
              sx={{
                color: "#00000099",
                "&.Mui-selected": {
                  color: "#7E57C2",
                },
              }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>{"Matching Properties"}</span>
                </Box>
              }
            />
            <Tab
              sx={{
                color: "#00000099",
                "&.Mui-selected": {
                  color: "#7E57C2",
                },
              }}
              label={`Documents (${documents?.length})`}
            />
          </Tabs>
          <Box mt={2}>
            {activeTab === 0 && <ActivityFeed clientData={clientData!} />}
            {activeTab === 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <MatchingProperties
                  clientId={clientData.id}
                  currentUser={currentUser}
                />
              </Box>
            )}

            {activeTab === 2 && (
              <PropertyDocuments
                documents={documents}
                fetchDocuments={fetchDocuments}
                currentUser={currentUser}
                clientId={clientData?.id}
              />
            )}
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          style={{ width: "100%", top: "80px", left: "50%" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage === "New changes have been successfully saved!"
                ? "success"
                : snackbarMessage === "Proceeding..."
                ? "info"
                : "error"
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default ClientProfile;
