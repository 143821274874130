import { Box } from "@mui/material";

import Agenda from "../agenda/Agenda";
import { Client } from "../../../../types";
import CreateNewEvent from "../../createNewEvent/CreateNewEvent";
import ClientInformation from "../clientInformation/ClientInformation";
import { useState } from "react";

interface ActivityProps {
  clientData: Client | null;
}

const ActivityFeed: React.FC<ActivityProps> = ({ clientData }) => {
  const [NewEventAction, setNewEventAction] = useState<boolean>(false);

  return (
    <Box sx={{ display: "flex", gap: "24px", marginTop: "32px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <ClientInformation clientData={clientData} />
        <CreateNewEvent
          clientEmail={clientData?.email}
          setNewEventAction={setNewEventAction}
        />
      </Box>
      <Agenda clientData={clientData} NewEventAction={NewEventAction} />
    </Box>
  );
};

export default ActivityFeed;
