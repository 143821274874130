import { Client, Employee, Property } from "../../../types";
import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CUSTOMER_COMMENT_FAILURE,
  CREATE_CUSTOMER_COMMENT_REQUEST,
  CREATE_CUSTOMER_COMMENT_SUCCESS,
  DELETE_CLIENT_DOCUMENT_FAILURE,
  DELETE_CLIENT_DOCUMENT_REQUEST,
  DELETE_CLIENT_DOCUMENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  GET_ALL_CLIENTS_REQUEST,
  GET_ALL_CLIENTS_SUCCESS,
  GET_CLIENT_BY_ID_FAILURE,
  GET_CLIENT_BY_ID_REQUEST,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_DOCUMENT_FAILURE,
  GET_CLIENT_DOCUMENT_REQUEST,
  GET_CLIENT_DOCUMENT_SUCCESS,
  GET_CUSTOMER_COMMENTS_FAILURE,
  GET_CUSTOMER_COMMENTS_REQUEST,
  GET_CUSTOMER_COMMENTS_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPLOAD_CLIENT_DOCUMENT_FAILURE,
  UPLOAD_CLIENT_DOCUMENT_REQUEST,
  UPLOAD_CLIENT_DOCUMENT_SUCCESS,
  ADD_PINNED_PROPERTY_REQUEST,
  ADD_PINNED_PROPERTY_SUCCESS,
  ADD_PINNED_PROPERTY_FAILURE,
  GET_PINNED_PROPERTY_REQUEST,
  GET_PINNED_PROPERTY_SUCCESS,
  GET_PINNED_PROPERTY_FAILURE,
  DELETE_PINNED_PROPERTY_REQUEST,
  DELETE_PINNED_PROPERTY_SUCCESS,
  DELETE_PINNED_PROPERTY_FAILURE,
} from "../../actions/clients/clients";

interface Document {
  id: string;
  name: string;
  type: string;
  url: string;
  uploadedAt: string;
  clientId: string;
}

interface Comment {
  id: number;
  message: string;
  employee: Employee;
  createdAt: Date;
}

interface PinnedProperties {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  property: Property[];
}

interface ClientsState {
  loading: boolean;
  clients: Client[];
  totalPages: number;
  error: string | null;
  documents?: Document[];
  comments?: Comment[];
  pinnedProperties: PinnedProperties[];
}

const initialState: ClientsState = {
  loading: false,
  clients: [],
  totalPages: 0,
  error: null,
  documents: [],
  comments: [],
  pinnedProperties: [],
};

interface GetAllClientsRequestAction {
  type: typeof GET_ALL_CLIENTS_REQUEST;
}

interface GetAllClientsSuccessAction {
  type: typeof GET_ALL_CLIENTS_SUCCESS;
  payload: Client[];
}

interface GetAllClientsFailureAction {
  type: typeof GET_ALL_CLIENTS_FAILURE;
  payload: string;
}

interface CreateClientRequestAction {
  type: typeof CREATE_CLIENT_REQUEST;
}

interface CreateClientSuccessAction {
  type: typeof CREATE_CLIENT_SUCCESS;
  payload: Client;
}

interface CreateClientFailureAction {
  type: typeof CREATE_CLIENT_FAILURE;
  payload: string;
}

interface DeleteClientRequestAction {
  type: typeof DELETE_CLIENT_REQUEST;
}

interface DeleteClientSuccessAction {
  type: typeof DELETE_CLIENT_SUCCESS;
  payload: Client;
}

interface DeleteClientFailureAction {
  type: typeof DELETE_CLIENT_FAILURE;
  payload: string;
}

interface GetClientByIdRequestAction {
  type: typeof GET_CLIENT_BY_ID_REQUEST;
}

interface GetClientByIdSuccessAction {
  type: typeof GET_CLIENT_BY_ID_SUCCESS;
  payload: Client;
}

interface GetClientByIdFailureAction {
  type: typeof GET_CLIENT_BY_ID_FAILURE;
  payload: string;
}

interface UpdateClientRequestAction {
  type: typeof UPDATE_CLIENT_REQUEST;
}

interface UpdateClientSuccessAction {
  type: typeof UPDATE_CLIENT_SUCCESS;
  payload: string;
}

interface UpdateClientFailureAction {
  type: typeof UPDATE_CLIENT_FAILURE;
  payload: string;
}

interface GetClientDocumentRequestAction {
  type: typeof GET_CLIENT_DOCUMENT_REQUEST;
}
interface GetClientDocumentSuccessAction {
  type: typeof GET_CLIENT_DOCUMENT_SUCCESS;
  payload: Document;
}
interface GetClientDocumentFailureAction {
  type: typeof GET_CLIENT_DOCUMENT_FAILURE;
  payload: string;
}

interface DeleteClientDocumentRequestAction {
  type: typeof DELETE_CLIENT_DOCUMENT_REQUEST;
}
interface DeleteClientDocumentSuccessAction {
  type: typeof DELETE_CLIENT_DOCUMENT_SUCCESS;
  payload: Document;
}
interface DeleteClientDocumentFailureAction {
  type: typeof DELETE_CLIENT_DOCUMENT_FAILURE;
  payload: string;
}

interface UploadClientDocumentRequestAction {
  type: typeof UPLOAD_CLIENT_DOCUMENT_REQUEST;
}
interface UploadClientDocumentSuccessAction {
  type: typeof UPLOAD_CLIENT_DOCUMENT_SUCCESS;
  payload: Document;
}
interface UploadClientDocumentFailureAction {
  type: typeof UPLOAD_CLIENT_DOCUMENT_FAILURE;
  payload: string;
}

export type EmployeesActionTypes =
  | GetAllClientsRequestAction
  | GetAllClientsSuccessAction
  | GetAllClientsFailureAction
  | CreateClientRequestAction
  | CreateClientSuccessAction
  | CreateClientFailureAction
  | DeleteClientRequestAction
  | DeleteClientSuccessAction
  | DeleteClientFailureAction
  | GetClientByIdRequestAction
  | GetClientByIdSuccessAction
  | GetClientByIdFailureAction
  | UpdateClientRequestAction
  | UpdateClientSuccessAction
  | UpdateClientFailureAction
  | GetClientDocumentRequestAction
  | GetClientDocumentSuccessAction
  | GetClientDocumentFailureAction
  | DeleteClientDocumentRequestAction
  | DeleteClientDocumentSuccessAction
  | DeleteClientDocumentFailureAction
  | UploadClientDocumentRequestAction
  | UploadClientDocumentSuccessAction
  | UploadClientDocumentFailureAction;

const clientsReducer = (state = initialState, action: any): ClientsState => {
  switch (action.type) {
    case GET_ALL_CLIENTS_REQUEST:
    case GET_CLIENT_BY_ID_REQUEST:
    case CREATE_CLIENT_REQUEST:
    case DELETE_CLIENT_REQUEST:
    case UPDATE_CLIENT_REQUEST:
    case GET_CLIENT_DOCUMENT_REQUEST:
    case DELETE_CLIENT_DOCUMENT_REQUEST:
    case UPLOAD_CLIENT_DOCUMENT_REQUEST:
    case GET_CUSTOMER_COMMENTS_REQUEST:
    case CREATE_CUSTOMER_COMMENT_REQUEST:
    case ADD_PINNED_PROPERTY_REQUEST:
    case GET_PINNED_PROPERTY_REQUEST:
    case DELETE_PINNED_PROPERTY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload.data,
        totalPages: action.payload.totalPages,
        error: null,
      };
    case CREATE_CUSTOMER_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: [...(state.comments || []), action.payload],
        error: null,
      };
    case ADD_PINNED_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        pinnedProperties: [...(state.pinnedProperties || []), action.payload],
        error: null,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: [...(state.clients || []), action.payload],
        error: null,
      };
    case GET_CLIENT_BY_ID_SUCCESS:
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload,
        error: null,
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: state.clients.filter(
          (client) => client.id !== action.payload.id
        ),
        error: null,
      };
    case GET_PINNED_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        pinnedProperties: action.payload,
        error: null,
      };
    case GET_CUSTOMER_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: action.payload,
        error: null,
      };
    case GET_CLIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload,
        error: null,
      };
    case DELETE_PINNED_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        pinnedProperties: state.pinnedProperties?.filter(
          (item) => item.id !== action.payload.id
        ),
        error: null,
      };
    case DELETE_CLIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: state.documents?.filter(
          (document) => document.id !== action.payload.id
        ),
        error: null,
      };

    case UPLOAD_CLIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: [...(state.documents || []), action.payload],
        error: null,
      };

    case GET_ALL_CLIENTS_FAILURE:
    case CREATE_CLIENT_FAILURE:
    case GET_CLIENT_BY_ID_FAILURE:
    case DELETE_CLIENT_FAILURE:
    case UPDATE_CLIENT_FAILURE:
    case GET_CLIENT_DOCUMENT_FAILURE:
    case DELETE_CLIENT_DOCUMENT_FAILURE:
    case UPLOAD_CLIENT_DOCUMENT_FAILURE:
    case GET_CUSTOMER_COMMENTS_FAILURE:
    case CREATE_CUSTOMER_COMMENT_FAILURE:
    case ADD_PINNED_PROPERTY_FAILURE:
    case GET_PINNED_PROPERTY_FAILURE:
    case DELETE_PINNED_PROPERTY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default clientsReducer;
