import { registeredUserDataProps } from "../../types";
import {
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "../actions/signup";

interface SignupState {
  loading: boolean;
  user: any;
  error: string | null;
}

const initialState: SignupState = {
  loading: false,
  user: null,
  error: null,
};

interface SignupRequestAction {
  type: typeof SIGNUP_REQUEST;
}

interface SignupSuccessAction {
  type: typeof SIGNUP_SUCCESS;
  payload: registeredUserDataProps;
}

interface SignupFailureAction {
  type: typeof SIGNUP_FAILURE;
  payload: string;
}

export type SignupActionTypes =
  | SignupRequestAction
  | SignupSuccessAction
  | SignupFailureAction;

const signupReducer = (
  state = initialState,
  action: any
): SignupState => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default signupReducer;
