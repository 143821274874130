import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { Property } from "../../../../types";
import pinIcon from "../../../../svg/pinIcon.svg";
import areaLogo from "../../../../svg/area-icon.svg";
import bedroomLogo from "../../../../svg/bedroom.svg";
import bathroomLogo from "../../../../svg/bathroom.svg";
import mailBoxIcon from "../../../../svg/mailBoxIcon.svg";
import { formatDate } from "../../../../helpers/formatDate";
import { formatBudget } from "../../../../helpers/formatBudget";
import mobilePhoneIcon from "../../../../svg/mobilePhoneIcon.svg";
import {
  deletePinnedProperty,
  getPinnedProperties,
} from "../../../../api/clientsApi";
import avataPlaceHolder from "../../../../svg/dunevilladubai.webp";
import { tokenStorage } from "../../../../helpers/storageFunctions";
import tooltipCopyLogo from "../../../../svg/tooltip-copy-logo.svg";
import employeesPlaceholder from "../../../../svg/employeesIcon.svg";
import avatarPlaceholder from "../../../../helpers/avatarPlaceholder";
import { getValueFromKey } from "../../../../helpers/propertyBuildingTypeFormatter";
import style from "../../../dashboardPropertySection/dashboardPropertyTable/DashboardPropertyTable.module.css";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";

type MatchingPropertiesListProps = {
  pinnedProperties: any;
  clientId: number;
  setPinnedProperties: (value: Property[]) => void;
};

const MatchingPropertiesList: React.FC<MatchingPropertiesListProps> = ({
  pinnedProperties,
  clientId,
  setPinnedProperties,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const pinnedPropertiesList = useSelector(
    (state: RootState) => state.clients.pinnedProperties.length
  );

  const dispatch: AppDispatch = useDispatch();
  const token = tokenStorage().getAccessToken();
  const navigate = useNavigate();

  const handleOpenBrokerProfile = (id: any) => {
    navigate(`/employees/${id}`);
  };

  const handleCopy = (text: any) => {
    navigator.clipboard.writeText(text);
    setMessage(`${text} copied!`);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDeletePinnedProperty = async (propertyId: number) => {
    if (!token) {
      return;
    }

    try {
      const response = await dispatch(deletePinnedProperty(propertyId, token));

      if (response.status !== 200) {
        return;
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchPinnedProperties = async () => {
      if (!token) {
        return;
      }

      try {
        const response = await dispatch(getPinnedProperties(clientId, token));

        if (response.status !== 200) {
          return;
        }

        setPinnedProperties(response.data);
        return response;
      } catch (error) {
        console.log(error);
      }
    };

    fetchPinnedProperties();
  }, [token, clientId, dispatch, pinnedPropertiesList, setPinnedProperties]);

  return (
    <div style={{ marginTop: "16px" }}>
      {pinnedProperties.map(({ property, id }: { property: Property, id: number }) => {
        const formatedDate = formatDate(property.offerDate);
        return (
          <Card
          onClick={() => navigate(`/property/${property.id}`)}
            className={style.card}
            key={property.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "104px",
              marginBottom: "12px",
              boxShadow: "none",
              cursor: "pointer",
              backgroundColor: "white",
              border: "1px solid #00000033",
              overflow: "auto",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  sx={{ width: 140, height: "100%" }}
                  image={
                    property.images && property.images.length > 0
                      ? property.images[0]?.url
                      : avataPlaceHolder
                  }
                  alt={property.name}
                />
                <Button
                  sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    padding: 0,
                    minWidth: "24px",
                    minHeight: '24px',
                    borderRadius: '100px',
                    background: '#FFFFFF99'
                  }}
                  onClick={(e) => {handleDeletePinnedProperty(Number(id)); e.preventDefault(); e.stopPropagation()}}
                >
                 <img src={pinIcon} alt="pin" />
                </Button>
              </Box>
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px",
                  height: "104px",
                }}
              >
                <div
                  style={{
                    margin: "12px 0px",
                    padding: "0px 14px",
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          marginRight: "5px",
                        }}
                      >
                        {property.name},
                      </span>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#00000099",
                          marginRight: "7px",
                        }}
                      >
                        {`${getValueFromKey(property.buildingType)} for ${
                          property.dealType === "BUY"
                            ? "Buying"
                            : property.dealType === "RENT"
                            ? "Rent"
                            : "Off-plan"
                        }`}
                      </span>
                      <Chip
                        variant="outlined"
                        size="small"
                        label={
                          property.status === "AVAILABLE"
                            ? "Vacant"
                            : property.status === "SOLD"
                            ? `Sold on ${formatedDate}`
                            : `Rented till ${formatedDate}`
                        }
                        color={
                          property.status === "AVAILABLE"
                            ? "success"
                            : property.status === "SOLD"
                            ? "error"
                            : "primary"
                        }
                      />
                    </div>
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 1,
                    }}
                  >
                    <LocationOnIcon color="action" />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {`Street ${property.street}, Building ${
                        property.building
                      }, ${
                        property.apartment > 0
                          ? `Apartment ${property.apartment},`
                          : ""
                      } ${property.district.name}`}
                    </Typography>
                  </Box>
                </div>
              </CardContent>
            </Box>
            <div style={{ display: "flex" }}>
              <Box
                display={"flex"}
                borderLeft={"1px solid #00000033"}
                borderRight={"1px solid #00000033"}
                margin={"12px 0px"}
                padding={"0px 14px"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  paddingBlock={"10px"}
                  width={"149px"}
                >
                  <Typography style={{ fontWeight: "500", fontSize: "14px" }}>
                    {`AED ${formatBudget(property.price)}`}
                  </Typography>
                  <Typography style={{ fontWeight: "400", fontSize: "14px" }}>
                    {"ID"} {property.id}
                  </Typography>
                </Box>
                <span
                  style={{
                    borderRight: "1px solid #00000033",
                    marginInline: "14px",
                  }}
                ></span>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  paddingBlock={"10px"}
                  width={"165px"}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "32px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={bedroomLogo}
                        alt="area pic"
                      />{" "}
                      {property.bedrooms}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={bathroomLogo}
                        alt="area pic"
                      />{" "}
                      {property.bathrooms}
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={areaLogo}
                      alt="area pic"
                    />{" "}
                    {property.area} {"sqft"}
                  </Typography>
                </Box>
              </Box>

              {property.broker?.id ? (
                <Box
                  onClick={(e) => {
                    handleOpenBrokerProfile(property.broker?.id);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: 2,
                    width: "210px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      alt={`${property.broker?.firstName} ${property.broker?.lastName}`}
                      src={
                        property.broker?.avatarURL ??
                        `${avatarPlaceholder(
                          property.broker?.firstName,
                          property.broker?.lastName
                        )}`
                      }
                      sx={{ width: "40px", height: "40px", marginRight: "8px" }}
                    />
                    <div style={{ height: "40px" }}>
                      <Typography>
                        {property.broker?.firstName} {property.broker?.lastName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {property.broker?.role}
                      </Typography>
                    </div>
                  </div>
                  <Box sx={{ display: "flex", marginTop: 1, gap: "8px" }}>
                    <Tooltip
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            handleCopy(property.broker?.phone);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <span style={{ marginRight: "4px" }}>
                            {property.broker.phone}
                          </span>
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "#7E57C2",
                            backgroundColor: "white",
                            fontSize: "12px",
                            padding: "8px",
                            boxShadow:
                              "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                          },
                        },
                      }}
                    >
                      <Button
                        style={{
                          border: "1px solid #7E57C2",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          handleCopy(property.broker?.phone);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <img
                          width={"9px"}
                          height={"15px"}
                          src={mobilePhoneIcon}
                          alt="phone icon"
                        />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      title={
                        <Box
                          onClick={(e) => {
                            handleCopy(property.broker?.email);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <span style={{ marginRight: "4px" }}>
                            {property.broker.email}
                          </span>
                          <img
                            src={tooltipCopyLogo}
                            alt="logo"
                            style={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "#7E57C2",
                            backgroundColor: "white",
                            fontSize: "12px",
                            padding: "8px",
                            boxShadow:
                              "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                          },
                        },
                      }}
                    >
                      <Button
                        onClick={(e) => {
                          handleCopy(property.broker?.email);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        style={{ border: "1px solid #7E57C2" }}
                      >
                        <img
                          width={"15px"}
                          height={"12px"}
                          src={mailBoxIcon}
                          alt="mail icon"
                        />
                      </Button>
                    </Tooltip>

                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      href={property.broker?.whatsAppLink}
                      style={{ border: "1px solid #7E57C2" }}
                    >
                      <WhatsAppIcon style={{ color: "green" }} />
                    </Button>

                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={handleCloseSnackbar}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        {message}
                      </Alert>
                    </Snackbar>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: 2,
                    width: "210px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      alt={"employeesPlaceholder"}
                      src={employeesPlaceholder}
                      sx={{ width: "40px", height: "40px", marginRight: "8px" }}
                    />
                    <Typography>{"Not assigned"}</Typography>
                  </div>
                </Box>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default MatchingPropertiesList;
