import { AppDispatch } from "../redux/store";
import { GET_WORK_AREAS_FAILURE, GET_WORK_AREAS_REQUEST, GET_WORK_AREAS_SUCCESS } from "../redux/actions/workAreas";
import api from "./axiosInterceptor";

const DB_URL = process.env.REACT_APP_BASE_URL

export const getWorkAreas = (token: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: GET_WORK_AREAS_REQUEST,
        });

        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
  
        const response = await api.get(`${DB_URL}areas`, config);
  
        dispatch({
          type: GET_WORK_AREAS_SUCCESS,
          payload: response.data,
        });
  
        return response
      } catch (error: any) {
        dispatch({
          type: GET_WORK_AREAS_FAILURE,
          payload: error.message || "Failed to fetch all properties!",
        });
      }
    };
  };