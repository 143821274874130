import { AppDispatch } from "../redux/store";
import { Client } from "../types";
import {
  ADD_PINNED_PROPERTY_FAILURE,
  ADD_PINNED_PROPERTY_REQUEST,
  ADD_PINNED_PROPERTY_SUCCESS,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CUSTOMER_COMMENT_FAILURE,
  CREATE_CUSTOMER_COMMENT_REQUEST,
  CREATE_CUSTOMER_COMMENT_SUCCESS,
  DELETE_CLIENT_DOCUMENT_FAILURE,
  DELETE_CLIENT_DOCUMENT_REQUEST,
  DELETE_CLIENT_DOCUMENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_PINNED_PROPERTY_FAILURE,
  DELETE_PINNED_PROPERTY_REQUEST,
  DELETE_PINNED_PROPERTY_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  GET_ALL_CLIENTS_REQUEST,
  GET_ALL_CLIENTS_SUCCESS,
  GET_CLIENT_BY_ID_FAILURE,
  GET_CLIENT_BY_ID_REQUEST,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_DOCUMENT_FAILURE,
  GET_CLIENT_DOCUMENT_REQUEST,
  GET_CLIENT_DOCUMENT_SUCCESS,
  GET_CUSTOMER_ACTIVITY_FAILURE,
  GET_CUSTOMER_ACTIVITY_REQUEST,
  GET_CUSTOMER_ACTIVITY_SUCCESS,
  GET_CUSTOMER_COMMENTS_FAILURE,
  GET_CUSTOMER_COMMENTS_REQUEST,
  GET_CUSTOMER_COMMENTS_SUCCESS,
  GET_PINNED_PROPERTY_FAILURE,
  GET_PINNED_PROPERTY_REQUEST,
  GET_PINNED_PROPERTY_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPLOAD_CLIENT_DOCUMENT_FAILURE,
  UPLOAD_CLIENT_DOCUMENT_REQUEST,
  UPLOAD_CLIENT_DOCUMENT_SUCCESS,
} from "../redux/actions/clients/clients";
import { ClientsInitialDataProps } from "../components/clientsSection/ quickAddMenu/config";
import { ClientsEditInitialDataProps } from "../components/clientsSection/clientProfile/editClientMenu/config";
import api from "./axiosInterceptor";
import { MatchingProps } from "../components/clientsSection/matchingProperties/config";

const DB_URL = process.env.REACT_APP_BASE_URL;

export const getAllClients = (
  token: string,
  page: number,
  limit: number,
  sortOrder?: string,
  sortBy?: string,
  fullName?: string,
  type?: string[],
  status?: string[],
  brokerId?: number
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_ALL_CLIENTS_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          limit,
          sortBy,
          sortOrder,
          fullName: fullName === "" ? null : fullName,
          type: type ?? null,
          status: status ?? null,
          brokerId: brokerId,
        },
      };

      const response = await api.get(`${DB_URL}clients`, config);

      dispatch({
        type: GET_ALL_CLIENTS_SUCCESS,
        payload: response.data.items,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_ALL_CLIENTS_FAILURE,
        payload: error.message || "Failed to fetch all clients!",
      });
    }
  };
};

export const getClientById = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_CLIENT_BY_ID_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.get(`${DB_URL}clients/${id}`, config);

      dispatch({
        type: GET_CLIENT_BY_ID_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_CLIENT_BY_ID_FAILURE,
        payload: error.message || "Failed to fetch client!",
      });
    }
  };
};

export const createClient = (
  token: string,
  clientData: Partial<ClientsInitialDataProps>
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: CREATE_CLIENT_REQUEST,
      });

      const config = {
        headers: {
          Authorzation: `Bearer ${token}`,
        },
      };

      const response = await api.post(
        `${DB_URL}clients/create`,
        clientData,
        config
      );

      const formattedClient: Client = {
        id: response.data.id,
        fullName: response.data.fullName,
        email: response.data.email,
        phone: response.data.phone,
        birthday: response.data.birthday,
        whatsAppLink: response.data.whatsAppLink,
        address: response.data.address,
        budget: response.data.budget,
        areas: response.data.areas,
        bedrooms: response.data.bedrooms,
        broker: response.data.broker,
        status: response.data.status,
        type: response.data.type,
        availability: response.data.availability,
        createdAt: response.data.createdAt,
      };

      dispatch({
        type: CREATE_CLIENT_SUCCESS,
        payload: formattedClient,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: CREATE_CLIENT_FAILURE,
        payload: error.message || "Failed to create client!",
      });

      return error.response;
    }
  };
};

export const updateClient = (
  id: string,
  values: ClientsEditInitialDataProps,
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: UPDATE_CLIENT_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const requestBody = {
        fullName: values.fullName,
        email: values.email !== "" ? values.email : null,
        phone: values.phone,
        budget: Number(values.budget),
        bedrooms: values.bedrooms,
        whatsAppLink: values.whatsAppLink,
        areas: values.areas,
        brokerId: values.brokerId,
        type: values.type,
        availability: values.availability?.toUpperCase(),
        status: values.status,
        birthday: values.birthday,
        address: values.address,
      };

      const response = await api.put(
        `${DB_URL}clients/${id}`,
        requestBody,
        config
      );

      dispatch({
        type: UPDATE_CLIENT_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: UPDATE_CLIENT_FAILURE,
        payload: error.message || "Failed to update client!",
      });

      return error.response;
    }
  };
};

export const deleteClient = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DELETE_CLIENT_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.delete(`${DB_URL}clients/${id}`, config);

      dispatch({
        type: DELETE_CLIENT_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: DELETE_CLIENT_FAILURE,
        payload: error.message || "Failed to delete client!",
      });
    }
  };
};

export const getClientDocuments = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_CLIENT_DOCUMENT_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get(
        `${DB_URL}clients/documents/${id}`,
        config
      );
      dispatch({
        type: GET_CLIENT_DOCUMENT_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_CLIENT_DOCUMENT_FAILURE,
        payload: error.message || "Failed to fetch documents!",
      });
    }
  };
};

export const deleteClientDocument = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DELETE_CLIENT_DOCUMENT_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.delete(
        `${DB_URL}clients/delete-document/${id}`,
        config
      );
      dispatch({
        type: DELETE_CLIENT_DOCUMENT_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: DELETE_CLIENT_DOCUMENT_FAILURE,
        payload: error.message || "Failed to delete document!",
      });
    }
  };
};

export const uploadClientDocument = (
  id: string,
  documents: File[],
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: UPLOAD_CLIENT_DOCUMENT_REQUEST,
      });

      const formData = new FormData();
      documents.forEach((document) => {
        formData.append("files", document);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await api.post(
        `${DB_URL}clients/upload-document/${id}`,
        formData,
        config
      );

      dispatch({
        type: UPLOAD_CLIENT_DOCUMENT_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: UPLOAD_CLIENT_DOCUMENT_FAILURE,
        payload: error.message || "Failed to upload document!",
      });
    }
  };
};

export const getCustomerComments = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_CUSTOMER_COMMENTS_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get(
        `${DB_URL}comments/customerComments/${id}`,
        config
      );
      dispatch({
        type: GET_CUSTOMER_COMMENTS_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_CUSTOMER_COMMENTS_FAILURE,
        payload: error.message || "Failed to fetch documents!",
      });
    }
  };
};

export const createCustomerComment = (
  message: string,
  targetId: string,
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: CREATE_CUSTOMER_COMMENT_REQUEST,
      });

      const requestBody = {
        message,
        targetId,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.post(
        `${DB_URL}comments/customer/create`,
        requestBody,
        config
      );

      dispatch({
        type: CREATE_CUSTOMER_COMMENT_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: CREATE_CUSTOMER_COMMENT_FAILURE,
        payload: error.message || "Failed to fetch documents!",
      });
    }
  };
};

export const getCustomerActivity = (id: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_CUSTOMER_ACTIVITY_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.get(
        `${DB_URL}activity/customerActivity/${id}`,
        config
      );

      dispatch({
        type: GET_CUSTOMER_ACTIVITY_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_CUSTOMER_ACTIVITY_FAILURE,
        payload: error.message || "Failed to fetch activities!",
      });
    }
  };
};

export const getCustomerMatchingPreferences = async (
  id: string,
  token: string
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.get(`${DB_URL}matching-property/${id}`, config);

    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const updateCustomerMatchingPreferences = async (
  id: string,
  values: MatchingProps,
  token: string
) => {
  try {
    const requestBody = {
      dealType: values.dealType ? values.dealType : null,
      availability: values.availability ? values.availability : null,
      type: values.type ? values.type : null,
      areas: values.areas ? values.areas : null,
      minSqft: values.minSqft ? Number(values.minSqft) : null,
      maxSqft: values.maxSqft ? Number(values.maxSqft) : null,
      minPrice: values.minPrice ? Number(values.minPrice) : null,
      maxPrice: values.maxPrice ? Number(values.maxPrice) : null,
      bedrooms: values.bedrooms ? values.bedrooms : null,
      bathrooms: values.bathrooms ? values.bathrooms : null,
      building: values.building ? Number(values.building) : null,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.put(
      `${DB_URL}matching-property/${id}`,
      requestBody,
      config
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const addPinnedProperty = (
  clientId: number,
  propertyId: number,
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: ADD_PINNED_PROPERTY_REQUEST,
      });

      const requestBody = { clientId, propertyId };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.post(
        `${DB_URL}pinned-properties`,
        requestBody,
        config
      );

      dispatch({
        type: ADD_PINNED_PROPERTY_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: ADD_PINNED_PROPERTY_FAILURE,
        payload: error.message || "Failed to fetch activities!",
      });
      return error.response;
    }
  };
};

export const getPinnedProperties = (clientId: number, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_PINNED_PROPERTY_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.get(
        `${DB_URL}pinned-properties/${clientId}`,
        config
      );

      dispatch({
        type: GET_PINNED_PROPERTY_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_PINNED_PROPERTY_FAILURE,
        payload: error.message || "Failed to fetch activities!",
      });
      return error.response;
    }
  };
};

export const deletePinnedProperty = (propertyId: number, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DELETE_PINNED_PROPERTY_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.delete(
        `${DB_URL}pinned-properties/${propertyId}`,
        config
      );

      dispatch({
        type: DELETE_PINNED_PROPERTY_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: DELETE_PINNED_PROPERTY_FAILURE,
        payload: error.message || "Failed to fetch activities!",
      });
      return error.response;
    }
  };
};
