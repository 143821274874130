export const shortenFileName = (fileName: string, length: number) => {
  if (fileName.length <= length) {
    return fileName;
  }
  return `${fileName.slice(0, length)}...`;
};

export const convertBytes = (bytes: number) => {
  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;

  if (kilobytes < 1) {
    return `${Math.floor(bytes)} bytes`;
  }

  return `${megabytes.toFixed(2)} mb`;
};
