import {
  SORT_BY_LATEST_FIRST,
  SORT_BY_NAME_AZ,
  SORT_BY_OLDEST_FIRST,
} from "../../actions/filters/filters";

export interface SortState {
  criteria: "LATEST_FIRST" | "OLDEST_FIRST" | "BY_NAME_AZ";
}

const initialState: SortState = {
  criteria: "LATEST_FIRST",
};

export const sortReducer = (state = initialState, action: any): SortState => {
  switch (action.type) {
    case SORT_BY_LATEST_FIRST:
      return { ...state, criteria: "LATEST_FIRST" };
    case SORT_BY_OLDEST_FIRST:
      return { ...state, criteria: "OLDEST_FIRST" };
    case SORT_BY_NAME_AZ:
      return { ...state, criteria: "BY_NAME_AZ" };
    default:
      return state;
  }
};
