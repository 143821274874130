/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Drawer,
  InputAdornment,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Typography,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../svg/closeIcon.svg";
import { Areas } from "../../../../types";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { getWorkAreas } from "../../../../api/workAreasApi";
import { tokenStorage } from "../../../../helpers/storageFunctions";
import plusIcon from "../../../../svg/plusIconBlue.svg";
import { propertyTypes } from "../../../propertySection/addPropertyForm/config";
import filterIcon from "../../../../svg/filterIcon.svg";
import { FormikProps } from "formik";
import { MatchingProps } from "../../matchingProperties/config";

const bedrooms = ["ANY", "STUDIO", "1", "2", "3", "4", "5", "6", "7+"];
const bathrooms = ["ANY", "1", "2", "3", "4", "5", "6", "7+"];

const dealType = {
  BUY: "Buy",
  RENT: "Rent",
  OFF_PLAN: "Off plan",
};

const availability = {
  VACANT: "Vacant",
  RENTED: "Rented",
  SOLD: "Sold",
};

type EditPreferencesMenuProps = {
  formik: FormikProps<MatchingProps>;
  matches?: number;
  handleMatching: (values: MatchingProps) => void;
  fetchProperties: (values: MatchingProps) => void;
};

const EditPreferencesMenu: React.FC<EditPreferencesMenuProps> = ({
  formik,
  matches,
  handleMatching,
  fetchProperties,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Areas[] | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [fields, setFields] = useState([
    { selectedLocation: "", street: "", houseNumber: "" },
  ]);

  const dispatch: AppDispatch = useDispatch();
  const token = tokenStorage().getAccessToken();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchWorkAreas = async () => {
      setIsLoading(true);
      if (token) {
        try {
          const result = await dispatch(getWorkAreas(token));
          setLocations(result?.data);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching work areas:", error);
        }
      }
    };

    fetchWorkAreas();
  }, [dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddFields = () => {
    if (fields.length < 5) {
      setFields([
        ...fields,
        { selectedLocation: "", street: "", houseNumber: "" },
      ]);
    }
  };

  const handleRemoveFields = (index: number) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleResetForm = () => {
    formik.resetForm();
  };

  useEffect(() => {
    if (formik.errors["minPrice"]) {
      setSnackbarMessage("Adjust price range!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [formik.errors["minPrice"]]);

  useEffect(() => {
    if (formik.errors["minSqft"]) {
      setSnackbarMessage("Adjust area range!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [formik.errors["minSqft"]]);

  return (
    <div style={{ minWidth: "300px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: matches && matches > 0 ? "row" : "column",
          gap: "16px",
        }}
      >
        <Button
          onClick={handleClickOpen}
          variant="outlined"
          style={{
            borderColor: "#000000DE",
            color: "#000000DE",
          }}
        >
          <img
            width={18}
            height={18}
            src={filterIcon}
            alt="filter icon"
            style={{ marginRight: "8px" }}
          />
          {"CHANGE PREFERENCES"}
        </Button>

        <Button
          onClick={() => fetchProperties(formik.values)}
          variant="contained"
          sx={{
            transition: "background-color color 0.3s ease",
            "&:disabled": {
              backgroundColor: "#00000030",
              color: "grey",
            },
            "&:not(:disabled)": {
              "&:hover": {
                backgroundColor: "#673AB7",
                color: "white",
              },
              "&:active": {
                backgroundColor: "#5E35B1",
                color: "white",
              },
            },
            "&": {
              background: "#7E57C2",
              color: "white",
            },
          }}
        >
          {"FIND A MATCH"}
        </Button>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "500px",
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontWeight: 500, fontSize: "20px", color: "#000000DE" }}
                >
                  {"Preferences:"}
                </Typography>
                <Typography
                  sx={{ fontWeight: 400, fontSize: "12px", color: "#00000099" }}
                >
                  {
                    "Select the preferences of your client to find matching properties."
                  }
                </Typography>
              </Box>
              <Button
                sx={{ minWidth: "0px", padding: "0px" }}
                onClick={handleClose}
              >
                <img width={18} height={18} src={closeIcon} alt="filter icon" />
              </Button>
            </Box>
            <Divider
              sx={{ width: "100%", height: "2px", marginBlock: "20px" }}
            />

            <Box sx={{ display: "flex", gap: "50px", marginBottom: "10px" }}>
              {Object.entries(dealType).map(([key, value]) => (
                <FormControlLabel
                  sx={{ width: "200px" }}
                  key={key}
                  control={
                    <Checkbox
                      color="success"
                      checked={formik.values.dealType === key}
                      onChange={(event) => {
                        if (event.target.checked) {
                          formik.setFieldValue("dealType", key);
                        } else {
                          formik.setFieldValue("dealType", "");
                        }
                      }}
                    />
                  }
                  label={value}
                />
              ))}
            </Box>
            <Divider
              sx={{ width: "100%", height: "2px", marginBlock: "20px" }}
            />

            <Box sx={{ display: "flex", gap: "50px", marginBottom: "10px" }}>
              {Object.entries(availability).map(([key, value]) => (
                <FormControlLabel
                  sx={{ width: "200px" }}
                  key={key}
                  control={
                    <Checkbox
                      color="success"
                      checked={formik.values.availability === key}
                      onChange={(event) => {
                        if (event.target.checked) {
                          formik.setFieldValue("availability", key);
                        } else {
                          formik.setFieldValue("availability", "");
                        }
                      }}
                    />
                  }
                  label={value}
                />
              ))}
            </Box>
            <Divider
              sx={{ width: "100%", height: "2px", marginBlock: "20px" }}
            />

            <Box sx={{ display: "flex", gap: "16px", marginBlock: "16px" }}>
              <TextField
                label="Minimum sqft"
                value={
                  formik.values.minSqft !== undefined
                    ? formik.values.minSqft
                    : ""
                }
                onChange={(event) => {
                  const input = event.target.value;
                  formik.setFieldValue(
                    "minSqft",
                    input === "" ? "" : Number(input)
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"SQFT"}</InputAdornment>
                  ),
                }}
                fullWidth
              />
              <TextField
                label="Maximum sqft"
                value={
                  formik.values.maxSqft !== undefined
                    ? formik.values.maxSqft
                    : ""
                }
                onChange={(event) => {
                  const input = event.target.value;
                  formik.setFieldValue(
                    "maxSqft",
                    input === "" ? "" : Number(input)
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"SQFT"}</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Box>

            <Box sx={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
              <TextField
                label="Minimum price"
                value={
                  formik.values.minPrice !== undefined
                    ? formik.values.minPrice
                    : ""
                }
                onChange={(event) => {
                  const input = event.target.value;
                  if (/^\d*$/.test(input)) {
                    formik.setFieldValue(
                      "minPrice",
                      input === "" ? "" : Number(input)
                    );
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"AED"}</InputAdornment>
                  ),
                }}
                fullWidth
              />
              <TextField
                label="Maximum price"
                value={
                  formik.values.maxPrice !== undefined
                    ? formik.values.maxPrice
                    : ""
                }
                onChange={(event) => {
                  const input = event.target.value;
                  if (/^\d*$/.test(input)) {
                    formik.setFieldValue(
                      "maxPrice",
                      input === "" ? "" : Number(input)
                    );
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"AED"}</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Box>

            <TextField
              label="Type"
              select
              value={formik.values.type}
              onChange={(event) => {
                if (event.target.value) {
                  formik.setFieldValue("type", event.target.value);
                }
              }}
              fullWidth
              sx={{ marginBottom: "16px" }}
            >
              {propertyTypes.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Bedrooms"
              select
              value={formik.values.bedrooms}
              onChange={(event) => {
                if (event.target.value) {
                  formik.setFieldValue("bedrooms", event.target.value);
                }
              }}
              fullWidth
              sx={{ marginBottom: "16px" }}
            >
              {bedrooms.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Bathrooms"
              select
              value={formik.values.bathrooms}
              onChange={(event) => {
                if (event.target.value) {
                  formik.setFieldValue("bathrooms", event.target.value);
                }
              }}
              fullWidth
              sx={{ marginBottom: "16px" }}
            >
              {bathrooms.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
            <Box>
              {fields.map((field, index) => (
                <Box key={index} sx={{ marginBottom: "16px" }}>
                  <Typography sx={{ marginBlock: "20px" }}>
                    {"Location"}
                  </Typography>
                  <TextField
                    label="Area"
                    select
                    value={formik.values.areas || []}
                    onChange={(event) => {
                      if (event.target.value) {
                        formik.setFieldValue("areas", [event.target.value]);
                      }
                    }}
                    fullWidth
                    sx={{ marginBottom: "16px" }}
                  >
                    {!isLoading ? (
                      locations &&
                      locations.map((location) => (
                        <MenuItem key={location.id} value={location.name}>
                          {location.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>
                        <CircularProgress />
                      </MenuItem>
                    )}
                  </TextField>
                  <Box
                    sx={{ display: "none", alignItems: "center", gap: "16px" }}
                  >
                    <TextField
                      label="Street"
                      value={formik.values.street || ""}
                      onChange={(event) => {
                        if (event.target.value) {
                          formik.setFieldValue("street", event.target.value);
                        } else {
                          formik.setFieldValue("street", "");
                        }
                      }}
                      fullWidth
                    />
                    <TextField
                      label="Building"
                      value={formik.values.building || ""}
                      onChange={(event) => {
                        if (event.target.value) {
                          formik.setFieldValue("building", event.target.value);
                        } else {
                          formik.setFieldValue("street", "");
                        }
                      }}
                    />
                  </Box>

                  {fields.length > 1 && (
                    <Button
                      sx={{
                        alignSelf: "center",
                        height: "fit-content",
                        marginTop: "10px",
                        color: "#000000DE",
                      }}
                      onClick={() => handleRemoveFields(index)}
                    >
                      {"Remove"}
                    </Button>
                  )}
                </Box>
              ))}

              {fields.length < 5 && (
                <Button
                  onClick={handleAddFields}
                  sx={{
                    display: "none",
                    marginBlock: "22px",
                    maxWidth: "210px",
                    gap: "10px",
                    color: "#7E57C2",
                  }}
                >
                  <img src={plusIcon} alt="plus icon" />
                  {"ADD LOCATION"}
                </Button>
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "16px",
              }}
            >
              <Tooltip
                title={"This action will set previous values."}
                componentsProps={{
                  arrow: {
                    sx: {
                      color: "white",
                    },
                  },
                  tooltip: {
                    sx: {
                      color: "#7E57C2",
                      backgroundColor: "white",
                      fontSize: "12px",
                      padding: "8px",
                      boxShadow:
                        "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                    },
                  },
                }}
                placement="top-start"
                arrow
              >
                <Button
                  sx={{ color: "#000000DE" }}
                  onClick={() => {
                    handleResetForm();
                  }}
                >
                  {"CLEAR ALL"}
                </Button>
              </Tooltip>
              <Button
                onClick={() => handleClose()}
                type="submit"
                variant="contained"
                style={{
                  background: "#7E57C2",
                  color: "white",
                }}
                sx={{
                  transition: "background-color color 0.3s ease",
                  "&:disabled": {
                    backgroundColor: "#00000030 !important",
                    color: "grey",
                  },
                  "&:hover": {
                    backgroundColor: "#673AB7 !important",
                    color: "white",
                  },
                  "&:active": {
                    backgroundColor: "#5E35B1 !important",
                    color: "white",
                  },
                }}
                disabled={!formik.isValid}
              >
                {"SAVE AND FIND A MATCH"}
              </Button>
            </Box>
          </Box>
        </form>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditPreferencesMenu;
