/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { Employee } from "../../../../types";

interface Fields {
  type: string;
  status: string;
}

export interface ClientsEditInitialDataProps {
  id: string;
  fullName: string;
  email?: string;
  phone: string;
  budget: number | null;
  broker?: Employee | null;
  brokerId?: string;
  areas?: string[];
  bedrooms?: string;
  availability?: string;
  birthday?: string;
  whatsAppLink?: string;
  address?: string;
  status?: string;
  type?: string[];
  createdAt: Date;
}

export interface EditClientDataProps {
  fullName: string;
  email?: string;
  phone: string;
  amount: number;
  broker: string;
  area: string;
  bedrooms: number;
  availability: string;
  birthday?: Date;
  whatsAppLink?: string;
  address?: string;
  fields: Fields[];
}

export const ClientInitialData: ClientsEditInitialDataProps = {
  id: "",
  fullName: "",
  email: "",
  phone: "",
  budget: null,
  broker: null,
  brokerId: "",
  areas: [],
  bedrooms: "",
  availability: "",
  birthday: "",
  whatsAppLink: "",
  address: "",
  type: [],
  status: "",
  createdAt: new Date(),
};

const RegexConstants = {
  FULL_NAME: /^[A-Za-zА-Яа-яІіЄєء-يٱآإأىًٌٍَُِّْٰ\s.-]+$/,
};

export const validationSchema = Yup.object({
  fullName: Yup.string()
    .required("Required")
    .matches(RegexConstants.FULL_NAME, { message: "Invalid name!" })
    .test(
      "is-not-only-spaces",
      "Can't contain only spaces.",
      (value) => value?.trim().length > 0
    ),
  email: Yup.string().email("Invalid email address").nullable(),
  phone: Yup.string().required("Required"),
  budget: Yup.number().required("Required"),
  whatsAppLink: Yup.string()
    .nullable()
    .test(
      "isValidWhatsAppLink",
      "Please enter a valid WhatsApp link starting with 'https://wa.me/'",
      (value) => !value || value === "" || value.startsWith("https://wa.me/")
    ),
});
