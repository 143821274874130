import { Action } from "@reduxjs/toolkit";

export const SET_NAME_FILTER = 'SET_NAME_FILTER';

export const setNameFilter = (name: string) => ({
  type: SET_NAME_FILTER,
  payload: name,
});

export const SORT_BY_LATEST_FIRST = 'SORT_BY_LATEST_FIRST';
export const SORT_BY_OLDEST_FIRST = 'SORT_BY_OLDEST_FIRST';
export const SORT_BY_NAME_AZ = 'SORT_BY_NAME_AZ';

interface SortByLatestFirstAction extends Action<typeof SORT_BY_LATEST_FIRST> {}
interface SortByOldestFirstAction extends Action<typeof SORT_BY_OLDEST_FIRST> {}
interface SortByNameAZAction extends Action<typeof SORT_BY_NAME_AZ> {}

export const sortByLatestFirst = (): SortByLatestFirstAction => ({
  type: SORT_BY_LATEST_FIRST,
});

export const sortByOldestFirst = (): SortByOldestFirstAction => ({
  type: SORT_BY_OLDEST_FIRST,
});

export const sortByNameAZ = (): SortByNameAZAction => ({
  type: SORT_BY_NAME_AZ,
});